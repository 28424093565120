<template>
  <SingleLayout>
    <v-card class="mx-auto my-12 mt-0 mb-0" max-width="374">
      <div class="dialog-header">
        <div class="dialog-title">パスワード再設定</div>
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-list-item>
            <v-list-item-content>
              <Label label="ログインID">
                <InputText name="email" :values="formValue" :editable="false" />
              </Label>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <Label label="パスワード">
                <Password
                  name="password"
                  :values="formValue"
                  :editable="true"
                  validation_label="パスワード"
                  validation_rules="required|password"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <Label label="パスワード再入力">
                <Password
                  name="password_re"
                  :values="formValue"
                  :editable="true"
                  validation_label="パスワード"
                  :validation_rules="`required|password|passwordDiff:${formValue.password}`"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn
                height="48px"
                color="primary"
                depressed
                :disabled="invalid"
                :key="flagError"
                @click.once="Reset()"
                >再設定する</v-btn
              >
            </v-list-item-content>
          </v-list-item>
        </v-form>
      </ValidationObserver>
    </v-card>
  </SingleLayout>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SingleLayout from "@/components/layout/SingleLayout";
import InputText from "@/components/forms/elements/InputText.vue";
import Password from "@/components/forms/elements/Password.vue";
import Label from "@/components/forms/elements/Label.vue";
import { MESSAGES } from "@/constants/LOGIN.js";
import Popup from "@/components/common/Popup.vue";
const PAGE_TITLE = "らくらく点検";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  components: {
    SingleLayout,
    ValidationProvider,
    ValidationObserver,
    InputText,
    Password,
    Label,
    Popup,
  },
  data: () => {
    return {
      formValue: {
        email: "",
        password: "",
        password_re: "",
      },
      passwordShow: false,
      flagError: false,
    };
  },
  async mounted() {
    //トークンとログインidを取得
    const { reset_token, login_id } = this.$route.query;
    if (!reset_token || !login_id) {
      this.$router.push("/");
    }

    //login_idを登録
    const formValue = { ...this.formValue };
    formValue.email = login_id;
    this.formValue = formValue;

    await Store.dispatch("Login/setResetToken", { reset_token });
  },
  methods: {
    async Reset() {
      let params = { ...this.formValue };
      delete params?.password_re;
      const res = await Store.dispatch("Login/resetPassword", params);
      if (!res.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: MESSAGES.PASSWORD_RESETTING_SUCCESS,
        });
        Store.dispatch("Login/deleteToken");
        setTimeout(() => {
          this.$router.push("/");
        }, 500);
      } else {
        this.flagError = !this.flagError;
        Store.dispatch("Login/deleteToken");
        Store.dispatch("Error/show", {
          status: 200,
          message: res.response.data.message,
        });
      }
    },
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
      this.$emit("formUpdate", formValue);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialog.scss";
</style>
