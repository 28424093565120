import Api, { Mock } from "../api";
import mock from "./mock/constants.json";
import list_inspection from "./mock/list_inspection.json";

const PATH = "cmn_mst";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;
const URL_GET_LIST_PREFECTURES = `${URL}/get_list_prefectures`;
const LIST_INSPECTIONS_URL = `${URL}/get_list_inspection_items`;
const LIST_MAJOR_INSPECTION = `${URL}/get_list_major_inspection`;
const LIST_MEDIUM_INSPECTION = `${URL}/get_list_medium_inspection`;
const IMAGE_DEVICE_URL = `${URL}/get_image_device`;
const LIST_MAJOR_INSPECTION_HAS_MACHINE = `${URL}/list_major_inspection_has_machine`;
const LIST_MEDIUM_INSPECTION_HAS_MACHINE = `${URL}/list_medium_inspection_has_machine`;
const NOTIFICATION = `${URL}/get_notification`;
// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(URL_GET_LIST_PREFECTURES).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock,
  });
  Mock.onGet(LIST_INSPECTIONS_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: list_inspection,
  });
  Mock.onGet(IMAGE_DEVICE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: {
        image: "ブルドーザ",
        image_url: "/storage/image/1-3.jpg",
      },
    },
  });
  Mock.onGet(LIST_MAJOR_INSPECTION).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          major_classification_id: 1,
          major_classification: "ブルドーザ及びスクレーパ",
        },
        {
          major_classification_id: 2,
          major_classification: "掘削機械",
        },
        {
          major_classification_id: 3,
          major_classification: "積込機械",
        },
        {
          major_classification_id: 4,
          major_classification: "運搬機械",
        },
        {
          major_classification_id: 5,
          major_classification: "クレーン、エレベータ、高所作業車及びウインチ",
        },
        {
          major_classification_id: 6,
          major_classification: "基礎工事機械",
        },
        {
          major_classification_id: 7,
          major_classification: "せん孔機械、ブレーカ及びコンクリート破砕機",
        },
      ],
    },
  });
  Mock.onGet(new RegExp(`${LIST_MEDIUM_INSPECTION}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          middle_classification_id: 1,
          middle_classification: "ブルドーザ",
        },
        {
          middle_classification_id: 2,
          middle_classification: "モータースクレーパ",
        },
        {
          middle_classification_id: 3,
          middle_classification: "スクレープドーザ",
        },
        {
          middle_classification_id: 4,
          middle_classification: "被けん引式スクレーパ",
        },
      ],
    },
  });
  Mock.onGet(NOTIFICATION).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: null,
    },
  });
}

export const cmnMst = {
  get: (params) => {
    return Api.get(URL_GET_LIST_PREFECTURES, params);
  },
  getListInspections: (params) => {
    return Api.get(LIST_INSPECTIONS_URL, params);
  },
  getImageDevice: (params) => {
    return Api.get(IMAGE_DEVICE_URL, params);
  },
  getListMajorInspection: () => {
    return Api.get(LIST_MAJOR_INSPECTION);
  },
  getListMediumInspection: (params) => {
    return Api.get(LIST_MEDIUM_INSPECTION, { params: params });
  },
  getListMajorInspectionHasMachine: (params) => {
    return Api.get(LIST_MAJOR_INSPECTION_HAS_MACHINE, { params });
  },
  getListMediumInspectionHasMachine: (params) => {
    return Api.get(LIST_MEDIUM_INSPECTION_HAS_MACHINE, { params });
  },
  getNotification: (params) => {
    return Api.get(NOTIFICATION, { params });
  },
};
