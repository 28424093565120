<template>
  <v-card class="tab-three-content">
    <div class="scrollbar">
      <div
        class="container list-drop-drag"
        v-for="(item, key) in listMonthlyItem"
        :key="key"
      >
        <div class="list-data d-flex">
          <div class="drop-zone">
            <div class="drag-el justify-space-between d-flex">
              <input
                v-model="item.test_item"
                class="txt"
                @change="$emit('editMonthlyItem', listMonthlyItem, item)"
              />
              <v-icon
                class="btn-delete"
                @click="deleteTestItem(item.test_item_id)"
              >
                mdi-delete-outline
              </v-icon>
            </div>
          </div>
          <draggable
            class="drop-zone-lv2"
            v-model="item.inspection_details"
            @change="$emit('editMonthlyItem', listMonthlyItem, item)"
          >
            <div
              class="item-zone"
              v-for="value in item.inspection_details"
              :key="value.inspection_item_id"
            >
              <div class="drag-el">
                <div class="bg-el">
                  <input v-model="value.inspection_item" class="txt" />
                </div>
              </div>
              <div class="drag-el">
                <div class="bg-el justify-space-between d-flex">
                  <textarea
                    v-model="value.main_point"
                    class="txt-area"
                    @change="$emit('editMonthlyItem', listMonthlyItem, item)"
                  ></textarea>
                  <v-icon
                    @click="
                      deleteInspectionDetails(
                        value.inspection_item_id,
                        value.main_point_id,
                        item.test_item_id
                      )
                    "
                    class="btn-delete"
                  >
                    mdi-delete-outline
                  </v-icon>
                </div>
              </div>
            </div>
          </draggable>
        </div>
        <div class="action text-right">
          <v-btn class="mx-2 TableHeader__menu__icon btn-plus" small icon>
            <v-icon @click="pushData(item.test_item_id)"> mdi-plus </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "TabThreeContent",
  components: { draggable },
  props: {
    listMonthlyItem: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    pushData(id) {
      this.$emit("plusData", id);
    },
    deleteInspectionDetails(inspection_item_id, main_point_id, test_item_id) {
      this.$emit("deleteInspectionDetails", {
        inspection_item_id: inspection_item_id,
        main_point_id: main_point_id,
        test_item_id: test_item_id,
      });
    },
    deleteTestItem(test_item_id) {
      this.$emit("deleteTestItem", {
        test_item_id: test_item_id,
      });
    },
  },
};
</script>

<style type="text/css" scoped>
.tab-three-content {
  margin-top: 20px;
  border-radius: 0px !important;
  width: 100%;
  overflow-y: scroll;
  box-shadow: none !important;
  max-height: 480px;
}
.tab-three-content::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
  border-radius: 5px;
}
.tab-three-content::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 10px;
}
.drop-zone {
  width: 33%;
  padding-left: 10px;
  min-height: 10px;
  box-sizing: border-box;
}
.drop-zone .drag-el {
  color: #000;
  padding: 5px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.04);
}
.drop-zone .drag-el:nth-last-of-type(1) {
  margin-bottom: 0px;
}
.list-drop-drag {
  border-left: solid 10px #083874;
  margin-top: 10px;
}
.btn-plus {
  color: #00c3ac !important;
}
.btn-delete {
  font-size: 22px !important;
  cursor: pointer;
}
.drop-zone-lv2 {
  width: 66%;
}
.drop-zone-lv2 .item-zone {
  display: flex;
  flex-wrap: wrap;
}
.drop-zone-lv2 .drag-el {
  width: 50%;
  color: #000;
  padding-left: 10px;
}
.drop-zone-lv2 .drag-el .bg-el {
  background: rgba(0, 0, 0, 0.04);
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.txt {
  width: calc(100% - 38px) !important;
  font-size: 12px !important;
}

.txt-area {
  width: calc(100% - 38px) !important;
  max-height: fit-content;
  min-height: 20px;
  resize: none;
  font-size: 12px !important;
}
@media only screen and (max-height: 1129px) {
  .tab-three-content {
    height: 620px;
  }
}
@media only screen and (max-height: 770px) {
  .tab-three-content {
    height: 480px;
  }
}
</style>
