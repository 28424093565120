<template>
  <v-app id="app">
    <transition>
      <router-view />
    </transition>
    <ErrorMessage />
    <ToastMessage />
    <TokenErrorMessage />
    <GlobalHeaderDrawer />
  </v-app>
</template>

<script>
import Vue from "vue";
import ErrorMessage from "@/components/error/ErrorMessage";
import TokenErrorMessage from "@/components/error/TokenErrorMessage";
import ToastMessage from "@/components/toast/ToastMessage";
import GlobalHeaderDrawer from "@/components/globalHeader/components/GlobalHeaderDrawer";
import window from "@/plugins/window";
import { Store } from "@/store/Store";
import { LOGIN_FLAG_KEY } from "@/constants/LOGIN";

Vue.use(window);

export default {
  name: "App",
  components: {
    ErrorMessage,
    TokenErrorMessage,
    ToastMessage,
    GlobalHeaderDrawer,
  },
  async mounted() {
    this.getToken();
  },
  methods: {
    async getToken() {
      const flag = localStorage.getItem(LOGIN_FLAG_KEY);
      const isLoginPage = this.$route.path === "/";
      if (flag && !isLoginPage) {
        const response = await Store.dispatch("Login/loginReacqire");
        if (response.hasError) {
          // 再ログイン失敗でログイン画面へ遷移
          window.location.href = "/";
          return;
        }
        await Store.dispatch("GlobalHeader/init");
      }
    },
  },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
