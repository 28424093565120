<template>
  <!-- テーブルヘッダー -->
  <div ref="self">
    <v-toolbar flat>
      <v-toolbar-title class="header-title" v-if="isTitle">
        {{ pageTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="isSearch"
        class="mx-2 TableHeader__menu__icon"
        small
        icon
        @click="openSearch"
      >
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn
        class="mx-2 mr-5 TableHeader__menu__icon"
        :disabled="endUseMachineFieldStatus"
        small
        icon
        @click="$emit('openPrint')"
      >
        <v-icon class="icon_large"> mdi-printer </v-icon>
      </v-btn>
      <v-btn
        v-if="isDelete"
        class="mx-2 TableHeader__menu__icon"
        small
        icon
        @click="$emit('openRemoveDialog')"
        :disabled="multiRemoveStatus"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
      <v-btn
        v-if="isQrCode"
        depressed
        class="mx-2 TableHeader__menu__icon"
        color="primary"
        @click.stop="$emit('exportQrCode')"
        :disabled="multiRemoveStatus"
      >
        QRコード発行
      </v-btn>
      <v-btn
        v-if="isInvite"
        depressed
        class="mx-2 TableHeader__menu__icon"
        color="primary"
        @click="$emit('openInviteDialog')"
        :disabled="multiRemoveStatus"
      >
        招待
      </v-btn>
      <v-btn
        v-if="isRepresentative && isRuleRepresentative"
        class="ma-2 mr-5"
        depressed
        color="warning"
        :disabled="multiRemoveStatus"
        @click="$emit('onReject', true)"
      >
        代表者解除
      </v-btn>
      <v-btn
        v-if="isRepresentative && isRuleRepresentative"
        depressed
        class="ma-2 mr-5"
        color="primary"
        :disabled="multiRemoveStatus"
        @click="$emit('onApprove', false)"
      >
        代表者
      </v-btn>
      <v-btn
        depressed
        class="mx-2 use-flg-btn"
        :disabled="disabelEndUseStatus"
        @click="checkEndUseMachineField"
      >
        {{ useFlg }}
      </v-btn>
      <v-btn
        v-if="isAddNew"
        depressed
        class="mx-2"
        color="primary"
        :disabled="disableBtnAdd"
        @click="$emit('openItemForm')"
        data-testid="add-new-btn"
      >
        {{ addButtonTitle }}
      </v-btn>
      <v-btn
        v-if="isUpdate"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openUpdateForm')"
      >
        編集
      </v-btn>
    </v-toolbar>
    <div v-if="isShowSearch">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      isShowSearch: false,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    multiRemoveStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    // タイトルを表示
    isTitle: {
      type: Boolean,
      default: true,
    },
    // 検索ボタンを表示
    isSearch: {
      type: Boolean,
      default: true,
    },
    // 削除ボタンを表示
    isDelete: {
      type: Boolean,
      default: true,
    },
    // 新規追加ボタンを表示
    isAddNew: {
      type: Boolean,
      default: true,
    },
    // 招待ボタンを表示
    isInvite: {
      type: Boolean,
      default: false,
    },
    addButtonTitle: {
      type: String,
      default: "新規追加",
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    isRepresentative: {
      type: Boolean,
      default: false,
    },
    isRuleRepresentative: {
      type: Boolean,
      default: false,
    },
    isQrCode: {
      type: Boolean,
      default: false,
    },
    disableBtnAdd: {
      type: Boolean,
      default: false,
    },
    endUseMachineFieldStatus: {
      type: Boolean,
      default: false,
    },
    useFlg: {
      type: String,
      default: "機械終了",
    },
    isEndUseMachineField: {
      type: Number,
      default: 0,
    },
    disabelEndUseStatus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);
  },
  methods: {
    openSearch() {
      this.isShowSearch = !this.isShowSearch;
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
    checkEndUseMachineField() {
      this.isEndUseMachineField
        ? this.$emit("openCancelUseMachineFieldDialog")
        : this.$emit("openEndUseMachineFieldDialog");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/TableHeader.scss";
.v-toolbar__title.header-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.use-flg-btn {
  color: #ffff !important;
  background-color: #083874 !important;
}
</style>
