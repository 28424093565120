<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <FieldMachinesHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :endUseMachineFieldStatus="disablePrintBtn"
              :multiRemoveStatus="disableRemoveBtn"
              :disabelEndUseStatus="disabelEndUseBtn"
              :updateHeader="updateHeader"
              :isQrCode="true"
              :isSearch="false"
              :useFlg="useFlg"
              @exportQrCode="openDialogExport"
              addButtonTitle="新規登録"
              :isEndUseMachineField="isEndUseMachineField"
              @openEndUseMachineFieldDialog="openEndUseMachineFieldDialog"
              @openCancelUseMachineFieldDialog="openCancelUseMachineFieldDialog"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
              @openPrint="openPrint"
            >
            </FieldMachinesHeader>
            <SearchFormWrapper>
              <Label label="機械名">
                <InputText
                  name="middle_classification"
                  :editable="true"
                  :values="searchInputs"
                  @onInput="onChangeSearchInputs"
                />
              </Label>
              <Label
                label="所有会社"
                v-if="showSelectCompany"
                class="labelSearchWidthCustom"
              >
                <Select
                  name="company_id"
                  :editable="true"
                  :values="searchInputs"
                  :items="COMPANY_ITEM"
                  @onInput="onChangeSearchInputs"
                />
              </Label>
              <Label
                label="点検会社"
                v-if="showSelectCompany"
                class="labelSearchWidthCustom"
              >
                <Select
                  name="company_inspector"
                  :editable="true"
                  :values="searchInputs"
                  :items="COMPANY_INSPECTOR"
                  @onInput="onChangeSearchInputs"
                />
              </Label>
              <Label label="管理名（呼名）">
                <InputText
                  name="machine_field_name"
                  :editable="true"
                  :values="searchInputs"
                  @onInput="onChangeSearchInputs"
                />
              </Label>
              <SwitchInput
                name="end_use_flg"
                class="switch-position"
                label="終了した機械のみ表示"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <v-btn class="mx-2" color="primary" depressed @click="onSearch">
                検索
              </v-btn>
            </SearchFormWrapper>
            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              noDataText="データがありません。"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.middle_classification`]="{ item }">
                <div>
                  <v-icon v-if="isNew(item)" style="color: red"
                    >mdi-new-box</v-icon
                  >
                  {{ convertName(item.middle_classification, 50) }}
                </div>
              </template>
              <template v-slot:[`item.machine_field_name`]="{ item }">
                <div>
                  {{ convertName(item.machine_field_name, 50) }}
                </div>
              </template>
              <!-- TODO -->
              <template v-slot:[`item.goto_history`]="{ item }">
                <v-btn
                  color="primary"
                  depressed
                  @click.stop="showInspectionHistory(item.field_id)"
                >
                  点検票一覧
                </v-btn>
              </template>
              <template v-slot:[`item.remarks`]="{ item }">
                <div>
                  {{ convertName(item.remarks, 50) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <MachineSitesForm
        :item="editedItem"
        :isNewItem="isNewItem"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        @deleteIds="getDeleteIds"
        :key="isResetForm"
        ref="MachineSitesForm"
        :keyButton="keyButton"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        :isDelete="true"
        :isFieldMachineDetails="true"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowEndUseMachineFieldDialog">
      <ConfirmDialog
        @close="closeEndUseMachineFieldDialog"
        @yes="endUseMachineFields"
        :hasDetails="false"
        :isCenter="false"
        :isEndUse="true"
        title="機械終了"
        text="機械を終了しようとしています。
        機械を終了すると、
        現場機械一覧に表示されなくなります。

        実行しますか？"
        :items="selectedItems"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowCancelUseMachineFieldDialog">
      <ConfirmDialog
        @close="closeCancelMachineFieldDialog"
        @yes="cancelUseMachineFields"
        :hasDetails="false"
        :isCenter="false"
        :isUndo="true"
        title="終了取消"
        text="終了を取消しようとしています。
        現場機械一覧に表示されます。

        実行しますか？"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowPrintDialog">
      <ConfirmDialog
        @close="closePrintDialog"
        @yes="print"
        :isExport="true"
        title="現場機械リスト出力"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowExportDialog">
      <ConfirmDialog
        @close="closeExportDialog"
        @yes="exportListQrCode"
        :isExport="true"
        title="現場機械QRコード出力"
      />
    </Popup>

    <Popup width="480px" :dialog="isShowConfirmDialog">
      <DialogSaveMachine
        title="連番管理の機械を作成"
        :items="editedItem.machine_fields"
        @yes="closeItemForm"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (Common)
 *  Table common components and functions
 */

import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import TableLayout from "@/components/layout/TableLayout.vue";
import FieldMachinesHeader from "@/views/fields/fieldMachines/components/FieldMachinesHeader.vue";
import Pagination from "@/components/masterTable/elements/Pagination.vue";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper.vue";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper.vue";
import TableSort from "@/components/masterTable/elements/TableSort.vue";
import Popup from "@/components/common/Popup.vue";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";

import SwitchInput from "@/components/forms/elements/SwitchInput";

/**
 * Components and functions used by machine sites
 */
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import MachineSitesForm from "@/components/forms/fields/fieldMachines/MachineSitesForm.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import Label from "@/components/forms/elements/Label.vue";
import TabSelect from "@/components/forms/elements/TabSelect";

/**
 * import constants
 */
import {
  MACHINE_SITE_TABLE_LABELS,
  MACHINE_SITE_SORT_ITEMS,
  MACHINE_SITE_INITIAL_ITEM,
  MACHINE_SITE_TITLE,
} from "@/constants/MACHINE_SITES";
import _ from "lodash";
import { HEADER_MENU_ITEMS_FIELD } from "@/constants/GLOBALHEADER";
import { COMPANY_TYPE } from "@/constants/COMMON";
import axios from "axios";
import DialogSaveMachine from "../../../components/dialog/DialogSaveMachine.vue";

//page_title
const PAGE_TITLE = MACHINE_SITE_TITLE;

// Number of tables per page
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// Number of tables per page option
const SORT_ORDERS = TABLE_SORT_ORDERS;

// store name with call api
const STORE = "MachineSites";

// Table header label
const TABLE_LABELS = MACHINE_SITE_TABLE_LABELS;

// Sort element
const SORT_ITEMS = MACHINE_SITE_SORT_ITEMS;

// initial item
const INITIAL_ITEM = MACHINE_SITE_INITIAL_ITEM;

// DOWNLOAD_QR_CODE_URL
const DOWNLOAD_QR_CODE_URL = `${process.env.VUE_APP_API_BASE_URL}/reports/exportMachineFieldQRCode`;

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: "現場機材" };
    },
  },
  components: {
    DefaultLayout,
    TableLayout,
    FieldMachinesHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    ConfirmDialog,
    MachineSitesForm,
    InputText,
    Select,
    Label,
    TabSelect,
    SwitchInput,
    DialogSaveMachine,
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      COMPANY_ITEM: [],
      COMPANY_INSPECTOR: [],
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      items: [],
      selectedItems: [],
      isResetForm: false,
      editedItem: { ...INITIAL_ITEM },
      isNewItem: false,
      searchParams: {
        company_id: null,
        company_inspector: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
      },
      deleteIds: [],
      popups: {
        isShowItemForm: false,
        isShowRemoveDialog: false,
        isShowPrintDialog: false,
        isShowExportDialog: false,
        isShowEndUseMachineFieldDialog: false,
        isShowCancelUseMachineFieldDialog: false,
      },
      searchInputs: {
        middle_classification: null,
        company_id: null,
        company_inspector: null,
        end_use_flg: 0,
      },
      keyButton: false,
      isActive: false,
      useFlg: "機械終了",
      isEndUseMachineField: 0,
      isShowConfirmDialog: false,
    };
  },

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD.MACHINE_FIELDS.id,
    });
    this.getListCompany();
    /**
     * (Common)
     * Get data and pagination in list machine sites
     */

    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        const items = data[0];
        const now = new Date();
        const newDateNow = new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          now.getDate()
        );
        const itemsToMove = items.filter((item) => {
          const createdAt = new Date(item.created_at);
          const newCreatedAt = new Date(
            createdAt.getFullYear(),
            createdAt.getMonth() + 1,
            createdAt.getDate()
          );
          const dateDIff = newDateNow.getTime() - newCreatedAt.getTime();
          return dateDIff === 0;
        });
        const remainingItems = items.filter((item) => {
          const createdAt = new Date(item.created_at);
          const newCreatedAt = new Date(
            createdAt.getFullYear(),
            createdAt.getMonth() + 1,
            createdAt.getDate()
          );
          const dateDIff = newDateNow.getTime() - newCreatedAt.getTime();
          return dateDIff !== 0;
        });
        const reorderedItems = [...itemsToMove, ...remainingItems];
        this.items = reorderedItems;

        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
        this.getListFieldInspector();
      },
      {
        immidiate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters[`Company/getData`]],
      (data) => {
        this.COMPANY_ITEM = data[0];
      },
      {
        immidiate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      () => {
        this.getItems();
      },
      {
        deep: true,
      }
    );

    this.getItems();
  },

  computed: {
    /**
     * (スケジュール)
     * 現在の現場を取得
     */
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    disablePrintBtn() {
      return this.searchParams.end_use_flg === 1;
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.CURRENT_SITE.field_id,
        company_id: this.searchParams.company_id,
        company_inspector: this.searchParams.company_inspector,
        machine_field_name: this.searchParams.machine_field_name,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        middle_classification: this.searchParams.middle_classification,
        end_use_flg: this.searchParams.end_use_flg,
      };
    },
    USER_LOGIN() {
      return Store.getters[`Login/getUser`];
    },
    /**
     * Show select search if role is owner or admin
     */
    showSelectCompany() {
      if (
        this.USER_LOGIN?.company_type === COMPANY_TYPE.PATNER ||
        this.USER_LOGIN?.company_type === COMPANY_TYPE.RENTAL
      )
        return false;
      return true;
    },
    disabelEndUseBtn() {
      return this.selectedItems.length === 0;
    },
  },

  methods: {
    isNew(item) {
      const now = new Date();
      const createdAt = new Date(item.created_at);
      const newDateNow = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        now.getDate()
      );
      const newCreatedAt = new Date(
        createdAt.getFullYear(),
        createdAt.getMonth() + 1,
        createdAt.getDate()
      );
      const dateDIff = newDateNow.getTime() - newCreatedAt.getTime();
      if (dateDIff === 0) return true;
      return false;
    },
    getDeleteIds(deleteIds) {
      this.deleteIds = deleteIds;
    },
    /**
     *
     * pageUpdate
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * action when click button search
     */
    onSearch() {
      this.searchParams = { ...this.searchParams, ...this.searchInputs };
      this.searchParams["currentPage"] = 1;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },

    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },

    /**
     * change sort in search params
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * openItemForm
     * @param INITIAL_ITEMで定義している値
     */
    async openItemForm(item) {
      const params = { id: item.id };
      const machineSiteInfo = await Store.dispatch(
        `${STORE}/getMachineSitesDetail`,
        { params }
      );
      // Show Detail Form
      if (!machineSiteInfo.hasError) {
        this.editedItem = { ...machineSiteInfo.data.contents.entries };
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },

    //change end_use_flg status to 1
    async endUseMachineFields() {
      const ids = this.selectedItems.map((item) => item.id);
      await Store.dispatch(`${STORE}/endUseMachineField`, { ids });
      this.getItems();
      this.closeEndUseMachineFieldDialog();
    },

    //change end_use_flg status to null
    async cancelUseMachineFields() {
      const ids = this.selectedItems.map((item) => item.id);
      await Store.dispatch(`${STORE}/cancelUseMachineField`, { ids });
      this.getItems();
      this.closeCancelMachineFieldDialog();
    },

    //open new item form
    async openNewItemForm() {
      let _initial_item = { ...INITIAL_ITEM };
      _initial_item.field_id = this.CURRENT_SITE.field_id;
      this.editedItem = _.cloneDeep(_initial_item);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },

    //close item form
    closeItemForm() {
      this.popups.isShowItemForm = false;
      if (this.isShowConfirmDialog == true) {
        this.isShowConfirmDialog = false;
        this.setEmptyDataRegister();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.$nextTick(() => {
        this.editedItem = { ...INITIAL_ITEM };
        this.selectedItems = [];
      });
    },

    //open remove dialog form
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },

    //close remove dialog form
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    openCancelUseMachineFieldDialog() {
      this.popups.isShowCancelUseMachineFieldDialog = true;
    },

    closeCancelMachineFieldDialog() {
      this.popups.isShowCancelUseMachineFieldDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    openEndUseMachineFieldDialog() {
      this.popups.isShowEndUseMachineFieldDialog = true;
    },

    closeEndUseMachineFieldDialog() {
      this.popups.isShowEndUseMachineFieldDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    //update with new params
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    //get item by api params
    async getItems() {
      if (this.apiParams.end_use_flg != 1) {
        this.useFlg = "機械終了";
        this.isEndUseMachineField = 0;
      } else {
        this.useFlg = "終了取消";
        this.isEndUseMachineField = 1;
      }
      if (this.CURRENT_SITE.field_id) {
        await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
      }
    },

    //remove items with [ids]
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { ids });
      if (!result.hasError) {
        this.selectedItems = [];
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
      this.closeRemoveDialog();
    },

    showInspectionHistory() {},

    //submit machine sites form
    async submitForm(flag) {
      const hasId = "id" in this.editedItem;
      let _editedItem = _.cloneDeep(this.editedItem);
      delete _editedItem.acceptant_number;
      delete _editedItem.major_classification_id;
      delete _editedItem.middle_classification_id;
      delete _editedItem.middle_classification;
      delete _editedItem.major_classification;
      delete _editedItem.field_name;
      delete _editedItem.machine_name;
      delete _editedItem.company_name;
      delete _editedItem.company_id;
      delete _editedItem.inspector_name;
      delete _editedItem.temp_id;
      _editedItem.machine_field_inspections.forEach((e) => {
        delete e.modified_flg;
      });
      if (hasId) {
        delete _editedItem.machine_fields;
        _editedItem.quantity = parseInt(_editedItem.quantity);
      } else {
        let _machine_field_inspections =
          _editedItem.machine_field_inspections.filter((e) => e.id == null);
        _editedItem.machine_field_inspections = [..._machine_field_inspections];
        if (_editedItem.type == 2) {
          if (
            _editedItem.machine_fields[0].machine_field_name_prefix
              .machine_field_name_prefix
          ) {
            _editedItem.machine_fields[0].machine_field_name_prefix =
              _editedItem.machine_fields[0].machine_field_name_prefix.machine_field_name_prefix;
          }
          _editedItem.machine_fields[0].quantity = parseInt(
            _editedItem.machine_fields[0].quantity
          );
          _editedItem.machine_fields.forEach((e) => {
            _editedItem.quantity = e.quantity;
          });
          _editedItem.number_quantity = parseInt(_editedItem.quantity);
          _editedItem.machine_field_range = parseInt(
            _editedItem.machine_fields[0].machine_field_name_range
          );
        } else {
          _editedItem.machine_fields.forEach((e) => {
            e.quantity = parseInt(_editedItem.quantity);
          });
        }
        delete _editedItem.quantity;
      }
      if (this.deleteIds?.length > 0) {
        this.deleteIds.forEach((id) => {
          _editedItem.machine_field_inspections =
            _editedItem.machine_field_inspections.filter((item) => {
              return item.id != id;
            });
        });
      }
      _editedItem.machine_field_inspections.forEach((item) => {
        if (typeof item.id === "string") {
          delete item.id;
        }
      });
      const result = await Store.dispatch(
        hasId ? `${STORE}/update` : `${STORE}/add`,
        _editedItem
      );
      this.deleteIds = [];
      if (result.hasError) {
        this.keyButton = !this.keyButton;
        const { data } = result.response;
        let err = data.message;
        let msg = "";
        for (var key of Object.keys(err)) {
          msg += err[key][0];
        }
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: msg,
        });
      } else if (hasId) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: _editedItem.id };
        const resultDetail = await Store.dispatch(
          `${STORE}/getMachineSitesDetail`,
          { params }
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isResetForm = !this.isResetForm;
      } else {
        if (flag) {
          if (_editedItem.type == 2) {
            this.isShowConfirmDialog = true;
            this.getItems();
            return;
          } else {
            this.closeItemForm();
          }
        }
        this.setEmptyDataRegister();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
    },

    /**
     * get data select 所有会社
     */
    async getListCompany() {
      const response = await Store.dispatch(`Company/get`);
      if (!response.hasError) {
        const { entries } = response.data.contents;
        this.COMPANY_ITEM = [...entries];
        this.COMPANY_ITEM.unshift({
          id: null,
          name: "",
        });
      }
    },

    /**
     * get data select 点検会社
     */
    async getListFieldInspector() {
      if (this.CURRENT_SITE.field_id) {
        let apiParams = {
          field_id: this.CURRENT_SITE.field_id,
        };
        const res = await Store.dispatch(
          `${STORE}/getListMachineInspector`,
          apiParams
        );
        if (!res.hasError) {
          const data = res.data.contents.entries;
          this.COMPANY_INSPECTOR = [];
          this.COMPANY_INSPECTOR = [...data];
          this.COMPANY_INSPECTOR.unshift({
            id: null,
            name: "",
          });
        }
      }
    },
    setEmptyDataRegister() {
      const _editedItem = _.cloneDeep(this.editedItem);
      _editedItem.machine_fields.forEach((item) => {
        item.machine_number = null;
        item.machine_field_name = null;
        item.serial_number = null;
      });
      this.editedItem = _editedItem;
    },

    convertName(name, length) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > length) {
          result = name.substring(0, length) + "•••";
        }
      }
      return result;
    },

    openPrint() {
      this.popups.isShowPrintDialog = true;
    },

    closePrintDialog() {
      this.popups.isShowPrintDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    // print machine
    async print(type) {
      let params = {
        file_code: type,
        field_id: this.CURRENT_SITE.field_id,
      };
      let URL_EXPORT_FILE = `${process.env.VUE_APP_API_BASE_URL}/reports/machine_fields_list`;
      axios({
        url: URL_EXPORT_FILE,
        method: "GET",
        params: params,
        timeout: 8000,
        interceptors: {
          request: axios.interceptors.request.use((config) => {
            const GRN = JSON.parse(sessionStorage.getItem("GRN"));
            sessionStorage.getItem("GRN") &&
              (config.headers.Authorization = `${GRN.Login.api_token}`);
            return config;
          }),
        },
      })
        .then((response) => {
          if (response.data.status_code == 200) {
            this.closePrintDialog();
            const { file, file_name } = response.data.contents;
            const byteCharacters = atob(file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file_name);
            document.body.appendChild(link);
            link.click();
          } else {
            this.closePrintDialog();
            const error_status = response.data.status_code;
            let error_message = "";
            switch (true) {
              case /401/.test(error_status):
                error_message = "認証エラー";
                break;
              case /503/.test(error_status):
                error_message = "メンテナンス中です";
                break;
              default:
                error_message = "システムエラー";
            }
            Store.dispatch(
              "Error/show",
              {
                status: response.data.status,
                message: error_message,
              },
              { root: true }
            );
          }
        })
        .catch(() => {
          this.closePrintDialog();
          Store.dispatch(
            "Error/show",
            {
              status: 500,
              message: "システムエラー",
            },
            { root: true }
          );
        });
    },

    openDialogExport() {
      this.popups.isShowExportDialog = true;
    },
    closeExportDialog() {
      this.popups.isShowExportDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedLeadApproved = [];
      });
    },
    async exportListQrCode(type) {
      var today = new Date();
      let dateYYMMDD = `${today.getFullYear()}${today.getMonth()}${today.getDay()}`;
      let apiParams = [];
      this.selectedItems.map(function (value) {
        apiParams.push(value.id);
      });
      const configParams = {
        url: DOWNLOAD_QR_CODE_URL,
        params: {
          machine_file_id: apiParams,
          file_code: type,
        },
      };
      await Store.dispatch(`File/download`, configParams).then((response) => {
        let typeFile = type === 1 ? "application/xlsx" : "application/pdf";
        let blob = new Blob([response.data], { type: typeFile });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        if (type === 1) {
          link.download = `Machine_Fields_Qr_${this.CURRENT_SITE.field_id}${dateYYMMDD}.xlsx`;
        } else {
          link.download = `Machine_Fields_Qr_${this.CURRENT_SITE.field_id}${dateYYMMDD}.pdf`;
        }
        link.click();
        this.closeExportDialog();
      });
    },
    clickToggle: function () {
      this.isActive = !this.isActive;
    },
  },
};
</script>
<style lang="scss" scoped>
.switch-position {
  margin: 0 10px;
}

::v-deep .v-data-table__wrapper table tbody tr td:nth-child(5) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15vw;
}

::v-deep .v-data-table__wrapper table tbody tr td:nth-child(7) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 13vw;
}

::v-deep .v-input--selection-controls {
  margin-top: 22px;
}

.labelSearchWidthCustom {
  width: 20% !important;
}
</style>
