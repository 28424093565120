import axios from "axios";
import { Store } from "@/store/Store.js";

// apiがまだない場合にaxiosからモックを返すモジュール
import MockAdapter from "axios-mock-adapter";

// 共通のhttp情報を設定
const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 5000,
});

//トークンあれば返す
instance.interceptors.request.use((config) => {
  const GRN = JSON.parse(sessionStorage.getItem("GRN"));
  sessionStorage.getItem("GRN") &&
    (config.headers.Authorization = `${GRN.Login.api_token}`);
  return config;
});

// 通信エラー,400,500番台で何かしら通知を出す用
instance.interceptors.response.use(
  (response) => {
    let _response = { ...response };
    _response.hasError = false;

    if (!response.data.result) {
      const { status_code, message } = response.data;
      /**
       * apiエラー
       * - apiが返すエラー
       */
      Store.dispatch(
        "Error/show",
        {
          status: status_code,
          message: `apiエラー:${message}`,
        },
        { root: true }
      );
      _response.hasError = true;
      return _response;
    }
    return _response;
  },
  async (error) => {
    /**
     * 通信エラー
     * - ネットワーク接続がない場合
     * - サーバまでリクエストが届かない
     * など
     */
    if (!error.response) {
      let _error = { ...error };
      _error.hasError = true;
      Store.dispatch(
        "Error/show",
        { status: "500", message: "通信 エラー" },
        { root: true }
      );

      return _error;
    }

    /**
     * サーバエラー
     * - webサーバが返すエラー
     */
    //400,500番台エラー
    const error_status = error.response?.status;
    let error_message = "";
    let errorInfo = [];
    switch (true) {
      case /401/.test(error_status):
        error_message = "メールアドレスまたはパスワードが正しくありません。";
        break;
      case /403/.test(error_status):
        break;
      case /422/.test(error_status):
        for (let subMessage in error.response?.data["message"]) {
          errorInfo.push(...error.response?.data["message"][subMessage]);
        }
        error_message = "エラー: " + errorInfo.join("\n");
        break;
      case /502/.test(error_status):
        Store.dispatch("Error/showTokenError", null, { root: true });
        break;
      case /503/.test(error_status):
        error_message = "メンテナンス中です";
        break;
      default:
        error_message = "システムエラー";
    }
    let _error = { ...error };
    _error.hasError = true;

    Store.dispatch(
      "Error/show",
      {
        status: error_status,
        message: error_message,
      },
      { root: true }
    );

    return _error;
  }
);

// envでNODE_ENVが'local'の環境ではモックを返す
const Mock =
  process.env.VUE_APP_NODE_ENV === "local"
    ? new MockAdapter(instance, { delayResponse: 1000 }) //500msecレスポンスを遅らせる
    : false;

export { Mock };
export default instance;
