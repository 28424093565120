<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="popups.isShowConfirmDialog = true"
        >
          {{ DIALOG_BUTTON_NAMES.CANCEL }}
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid"
          color="primary"
          :key="keySubmit"
          @click.once="onSubmit"
        >
          {{ DIALOG_BUTTON_NAMES.SAVE }}
        </v-btn>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          {{ DIALOG_BUTTON_NAMES.EDIT }}
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <FormSite
              :editable="editable"
              :item="formValues"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="500px" :dialog="popups.isShowConfirmDialog">
        <ConfirmDialog
          @close="popups.isShowConfirmDialog = false"
          @yes="closeForm"
          :isClose="true"
          :isCenter="false"
          title="フォームを閉じる確認"
          text="フォームを閉じますがよろしいですか？
        入力内容は保存されません。

        このページから移動してもよろしいですか？"
        />
      </Popup>
      <Popup width="500px" :dialog="popups.isShowAddUserConfirmDialog">
        <ConfirmDialog
          @close="stopAction"
          @yes="moveToManagerTab"
          :hasDetails="false"
          :isCenter="false"
          :isConfirm="true"
          title="ユーザー追加確認画面"
          :text="confirmText"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import FormDialog from "@/components/dialog/FormDialog.vue";
import FormSite from "@/components/forms/fields/fieldUsers/FormSite.vue";
import { TITLE, FORMS } from "@/constants/FIELD_USER";
import { Store } from "@/store/Store.js";
import { DIALOG_BUTTON_NAMES } from "@/constants/COMMON";
//ストア
const STORE = "FieldUser";

export default {
  data: () => {
    return {
      DIALOG_BUTTON_NAMES,
      valid: false,
      tab: null,
      editable: true,
      popups: {
        isShowConfirmDialog: false,
        isShowAddUserConfirmDialog: false,
      },
      isClickCloseBtn: false,
      TITLE,
      FORMS,
      formValues: {
        users: [],
        fieldUsers: [],
      },
      beforeUpdateItem: {},
      keySubmit: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmDialog,
    FormSite,
  },
  mounted() {
    this.getItems();
    /**
     * mode: detail
     * get Company detail
     */
    this.$watch(
      () => [
        Store.getters[`Users/getUserAsign`],
        Store.getters[`${STORE}/getDataSort`],
        this.editable,
      ],
      (data) => {
        const _formValues = { ...this.formValues };
        _formValues["users"] = data[0];
        _formValues["fieldUsers"] = data[1];
        this.formValues = _formValues;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    formtitle() {
      return this.isNewItem ? this.TITLE.NEW : this.TITLE.EDIT;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    formUpdate(params) {
      this.formValues = { ...this.formValues, ...params };
    },
    onEditable() {
      this.editable = true;
    },
    onDetail() {
      this.editable = false;
    },
    async onSubmit() {
      const user_ids = this.formValues.fieldUsers.map((items) => items.id);
      const apiParams = {
        field_id: this.CURRENT_SITE.field_id,
        user_ids,
      };
      const result = await Store.dispatch(`${STORE}/update`, apiParams);
      this.keySubmit = !this.keySubmit;
      if (!result.hasError) {
        this.$emit("cancel");
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.$emit("updateChecked", user_ids);
      }
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },
    closeForm() {
      this.$emit("cancel");
    },
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.popups.isShowConfirmDialog = true;
      }
    },
    async getItems() {
      if (!this.isNewItem) {
        Store.dispatch(`${STORE}/getSort`, {
          field_id: this.CURRENT_SITE.field_id,
          sort_value: "user_name_kana",
          sort_by: 1,
        });
        Store.dispatch(`Users/getListUserForAsignToFields`, {
          sort_value: "user_name_kana",
          sort_by: 1,
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
