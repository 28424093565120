<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="id"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-autocomplete
      :attach="attach"
      v-model="val"
      :filter="filter"
      dense
      :items="items"
      :item-text="item_text"
      :item-value="item_value"
      :disabled="!editable"
      :success="valid"
      :filled="editable"
      :error-messages="errors"
      :label="label"
      @input="$emit('onInput', { name, value: val })"
      :data-testid="testid"
      :autofocus="autofocus"
    >
      <template v-if="isChangeMessage" v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ noDataText }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-action>
          <v-btn
            v-if="!isShowRegisterCompany"
            color="primary"
            @click="$emit('handleAction')"
          >
            {{ actionBtnText }}
          </v-btn>
        </v-list-item-action>
      </template>
    </v-autocomplete>
    <div class="pinAttach" :id="pinId"></div>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    validation_rules: {
      type: String,
      default: "",
    },
    validation_label: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
    },
    item_text: {
      type: String,
      default: "name",
    },
    item_value: {
      type: String,
      default: "id",
    },
    id: {
      type: String,
    },
    pinId: {
      type: String,
      default: "",
    },
    pinIdSpecial: {
      type: String,
      default: "",
    },
    filterCustom: {
      type: Boolean,
      default: false,
    },
    isChangeMessage: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No data available",
    },
    actionBtnText: {
      type: String,
      default: "新規登録",
    },
  },
  methods: {
    customFilter(item, queryText) {
      const itemName = item.name;
      let searchText = queryText;
      if (searchText.match(/(?<=\S)\s(?=\S)/g)) {
        return itemName.indexOf(searchText) > -1;
      } else {
        searchText = searchText.trim();
        return itemName.replace(/ +/g, "").indexOf(searchText) >= 0;
      }
    },
  },
  computed: {
    attach() {
      if (this.pinId !== "" || this.pinIdSpecial !== "") {
        return this.pinId === this.pinIdSpecial
          ? `#${this.pinIdSpecial}`
          : `#${this.pinId}`;
      } else {
        return undefined;
      }
    },
    filter() {
      return this.filterCustom ? this.customFilter : undefined;
    },
  },
  data: () => {
    return {
      val: "",
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
<style lang="scss" scoped>
.pinAttach {
  position: absolute;
  bottom: 82px;

  ::v-deep .v-menu__content {
    max-width: fit-content !important;
  }
}
</style>
