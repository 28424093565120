<template>
  <div class="pageManagerWrapper">
    <v-spacer></v-spacer>
    <div class="label">表示件数:</div>
    <div class="no">
      <v-select
        v-model="pageCount"
        dense
        :items="page_counts_options"
        @change="$emit('onInput', { name: 'pageCount', value: pageCount })"
      ></v-select>
    </div>

    <div class="label">総件数:</div>
    <div class="total_item">{{ total_item }}件</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    page_counts_options: {
      type: Array,
    },
    total_item: {
      type: Number,
    },
    values: {
      type: Object,
    },
    pageCount: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.pageManagerWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  height: 32px;
  .label {
    font-size: 12px;
    height: 32px;
    margin-right: 12px;
    white-space: nowrap;
  }
  .no {
    max-width: 100px;
    margin-right: 30px;
  }
  .total_item {
    padding-bottom: 15px;
    white-space: nowrap;
  }
}
</style>
