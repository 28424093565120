<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      :sm="editable ? '10' : '12'"
                      :md="editable ? '10' : '12'"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="機械名" :editable="editable" required>
                            <Select
                              pinId="selectMachineFormPage11"
                              name="temp_id"
                              :values="formValues"
                              :editable="editable"
                              item_text="middle_classification"
                              item_value="temp_id"
                              :items="MACHINE_MEDIUM_ITEM"
                              validation_label="機械名"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                      md="2"
                      style="margin: auto; padding-right: 16px"
                      v-if="editable"
                    >
                      <v-btn
                        style="min-width: 100%"
                        depressed
                        color="primary"
                        @click="onClickSearch"
                      >
                        検索
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            label="仕様・能力"
                            :editable="editable"
                            required
                          >
                            <InputText
                              name="name"
                              :values="formValues"
                              placeholder="仕様・能力"
                              :editable="editable"
                              validation_label="仕様・能力"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <Label label="所有会社" />
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="会社" :editable="editable">
                            <SelectWithFilter
                              pinId="selectFilterMachineFormPage11"
                              name="company_id"
                              :values="formValues"
                              :editable="editable"
                              :items="COMPANYS"
                              :isChangeMessage="true"
                              noDataText="会社の登録がありません"
                              @onInput="onInput"
                              @handleAction="isShowRegisterCompany = true"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="氏名" :editable="editable">
                            <SelectWithFilter
                              :filter-custom="true"
                              pinId="selectFilterMachineFormPage12"
                              name="user_id"
                              :values="formValues"
                              :editable="editable"
                              :items="USERS"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters> </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row
                    no-gutters
                    v-if="formValues['middle_classification_id']"
                  >
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="機械画像">
                            <v-img
                              contain
                              :src="url_image_device"
                              max-width="600px"
                              max-height="600px"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="備考" :editable="editable">
                            <InputTextArea
                              name="remarks"
                              :values="formValues"
                              :editable="editable"
                              :placeholder="editable ? '備考' : ''"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <Popup width="480px" :dialog="isShowSearchDialog">
      <SearchMachineDialog
        title="機械選択"
        :formValues="machineValues"
        @formMachineUpdate="formMachineUpdate"
        @onSearch="searchSite"
        @close="closeSearchMachineForm"
      />
    </Popup>
    <Popup :dialog="isShowRegisterCompany">
      <CompanyForm
        :isNewItem="true"
        @cancel="isShowRegisterCompany = false"
        @updateCompany="getListCompany"
      />
    </Popup>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import Select from "../elements/Select.vue";
import InputTextArea from "../elements/InputTextArea.vue";
import { Store } from "@/store/Store.js";
import { COMPANY_TYPE } from "@/constants/COMMON";
import Popup from "@/components/common/Popup.vue";
import SearchMachineDialog from "@/components/dialog/SearchMachineDialog.vue";
import SelectWithFilter from "../elements/SelectWithFilter.vue";
import CompanyForm from "@/components/forms/company/index";

export default {
  data: () => {
    return {
      isCompanyChanged: false,
      formValues: {},
      MACHINE_COMPANY_ITEM: Store.state.Company.data,
      MACHINE_MEDIUM_ITEM: [],
      url_image_device: "",
      COMPANY_TYPE,
      COMPANYS: [],
      USERS: [],
      isShowSearchDialog: false,
      isShowRegisterCompany: false,
      machineValues: {
        items: [],
        keyword: "",
        selected: null,
        major_id: null,
      },
    };
  },
  components: {
    Label,
    InputText,
    Select,
    InputTextArea,
    Popup,
    SearchMachineDialog,
    SelectWithFilter,
    CompanyForm,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
    backData: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.getListCompany();

    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        if (this.backData) {
          this.getImageDevice(
            this.formValues["major_classification_id"],
            this.formValues["middle_classification_id"]
          );
        }
        if (
          this.isNewItem &&
          this.UserLogin.company_type !== COMPANY_TYPE.ADMIN &&
          !this.isCompanyChanged
        ) {
          this.formValues["company_id"] = this.UserLogin.company_id;
        }
        if (this.formValues["company_id"]) {
          this.getListUser(this.formValues["company_id"]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["CmnMst/getListMediumInspection"],
      (data) => {
        this.machineValues.items = [...data];
      },
      {
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Company/getData`],
      (data) => {
        this.COMPANYS = [...data];
        this.COMPANYS.unshift({
          id: null,
          name: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
    await this.getListMediumInspection();
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (!flag) {
          this.getDataTempId();
          this.getImageDevice(
            this.formValues["major_classification_id"],
            this.formValues["middle_classification_id"]
          );
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    /**
     * user login
     */
    UserLogin() {
      return Store.state.Login.user;
    },
    LIST_USER() {
      return Store.getters[`Users/getData`];
    },
  },
  methods: {
    getDataTempId() {
      let formValues = { ...this.formValues };
      formValues["temp_id"] = this.MACHINE_MEDIUM_ITEM.find(
        (e) =>
          e.middle_classification_id === formValues.middle_classification_id &&
          e.major_classification_id === formValues.major_classification_id
      )?.temp_id;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    /**
     * get data select 中分類
     */
    async getListMediumInspection() {
      const response = await Store.dispatch(`CmnMst/getListMediumInspection`);
      if (!response.hasError) {
        const { entries } = response.data.contents;
        this.MACHINE_MEDIUM_ITEM = [...entries];
      }
    },
    async getImageDevice(major_id, middle_id) {
      let params = {
        major_classification_id: major_id,
        middle_classification_id: middle_id,
      };
      const response = await Store.dispatch(`CmnMst/getImageDevice`, {
        params,
      });
      if (!response.hasError) {
        let image_url = response.data.contents.entries.image_url;
        let be_url = process.env.VUE_APP_API_BASE_URL.replace("/api", "");
        this.url_image_device = be_url + image_url;
      }
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      if (name == "temp_id") {
        formValues["middle_classification_id"] = this.MACHINE_MEDIUM_ITEM.find(
          (e) => e.temp_id === value
        )?.middle_classification_id;
        formValues["major_classification_id"] = this.MACHINE_MEDIUM_ITEM.find(
          (e) => e.temp_id === value
        )?.major_classification_id;
        this.getImageDevice(
          formValues["major_classification_id"],
          formValues["middle_classification_id"]
        );
      }
      if (name == "company_id") {
        this.isCompanyChanged = true;
        this.USERS = [];
        formValues["user_id"] = null;
        this.getListUser(value);
      }
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    async onClickSearch() {
      await Store.dispatch("CmnMst/getListMediumInspection", {
        middle_classification: "",
      });
      this.isShowSearchDialog = true;
    },
    async searchSite() {
      let keyword = this.machineValues.keyword;
      await Store.dispatch("CmnMst/getListMediumInspection", {
        middle_classification: keyword,
      });
    },
    async formMachineUpdate(values) {
      this.machineValues = { ...values };
      if (this.machineValues.selected) {
        this.isShowSearchDialog = false;
        const formValues = { ...this.formValues };
        formValues.major_classification_id = this.machineValues.major_id;
        formValues.middle_classification_id = this.machineValues.selected;
        formValues["temp_id"] = this.MACHINE_MEDIUM_ITEM.find(
          (e) =>
            e.middle_classification_id ===
              formValues.middle_classification_id &&
            e.major_classification_id === formValues.major_classification_id
        )?.temp_id;
        formValues.machine_id = null;
        this.formValues = formValues;
        await this.getImageDevice(
          formValues["major_classification_id"],
          formValues["middle_classification_id"]
        );
        const machineValues = {
          items: [],
          middle_classification: "",
          selected: null,
          major_id: null,
        };
        this.machineValues = machineValues;
        this.$emit("formUpdate", formValues);
      }
    },

    closeSearchMachineForm() {
      this.isShowSearchDialog = false;
      const machineValues = {
        items: [],
        middle_classification: "",
        selected: null,
        major_id: null,
      };
      this.machineValues = machineValues;
    },
    async getListUser(company_id) {
      if (company_id) {
        let apiParams = {
          company_id: company_id,
        };
        const response = await Store.dispatch(`Users/get`, apiParams);
        if (!response.hasError) {
          let rs = [...this.LIST_USER];
          rs.forEach((e) => {
            e.name = e.name_sei + " " + e.name_mei;
          });
          this.USERS = [...rs];
          this.USERS.unshift({
            id: null,
            name: "",
          });
        }
      }
    },
    async getListCompany(params) {
      await Store.dispatch(`Company/get`);
      if (params) {
        this.isCompanyChanged = true;
        this.formValues["company_id"] = params;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
