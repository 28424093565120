<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <InhousePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              addButtonTitle="新規作成"
              :isSearch="false"
              :disableBtnAdd="disableBtnAdd"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
            >
            </TableHeader>
            <SearchFormWrapper>
              <Label label="現場名" class="labelSearchWidthCustom">
                <InputText
                  name="word"
                  :editable="true"
                  :values="searchInputs"
                  @onInput="onChangeSearchInputs"
                />
              </Label>
              <v-btn class="mx-2" color="primary" depressed @click="onSearch">
                検索
              </v-btn>
            </SearchFormWrapper>
            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              item-key="field_id"
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              noDataText="データがありません。"
              @dblclick:row="($event, { item }) => openItemForm(item)"
            >
              <template v-slot:[`item.address`]="{ item }">
                <div>
                  {{ getDetailAddress(item) }}
                </div>
              </template>
              <template v-slot:[`item.dowload_qr`]="{ item }">
                <v-btn
                  class="float-right"
                  color="primary"
                  depressed
                  @click.stop="dowloadQRCode(item.field_id)"
                >
                  QRコード
                </v-btn>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <SitesForm
        :item="editedItem"
        :isNewItem="isNewItem"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        :key="isResetForm"
        ref="SitesForm"
        :keyButton="keyButton"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        :isDelete="true"
        :isSiteDetails="true"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (Common)
 * Table common components and functions
 */

import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import TableLayout from "@/components/layout/TableLayout.vue";
import TableHeader from "@/components/masterTable/elements/TableHeader.vue";
import Pagination from "@/components/masterTable/elements/Pagination.vue";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper.vue";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper.vue";
import TableSort from "@/components/masterTable/elements/TableSort.vue";
import Popup from "@/components/common/Popup.vue";
import InhousePortalHeader from "@/components/globalHeader/InhousePortalHeader";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";

/**
 * Components and functions used by sites
 */
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import SitesForm from "@/components/forms/sites/SitesForm.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Select from "@/components/forms/elements/Select.vue";
import Label from "@/components/forms/elements/Label.vue";
import {
  SITE_TABLE_LABELS,
  SITE_SORT_ITEMS,
  SITE_INITIAL_ITEM,
  SITE_TITLE,
} from "@/constants/SITES";
import { COMPANY_TYPE } from "@/constants/COMMON";
import _ from "lodash";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";

//page_title
const PAGE_TITLE = SITE_TITLE;

// Number of tables per page
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// Number of tables per page option
const SORT_ORDERS = TABLE_SORT_ORDERS;

// Number of tables per page option
const STORE = "Sites";

// Table header label
const TABLE_LABELS = SITE_TABLE_LABELS;

// Sort element
const SORT_ITEMS = SITE_SORT_ITEMS;

// initial item
const INITIAL_ITEM = SITE_INITIAL_ITEM;

const DOWNLOAD_QR_CODE_URL = `${process.env.VUE_APP_API_BASE_URL}/reports/exportFieldQRCode`;

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    InhousePortalHeader,
    Popup,
    ConfirmDialog,
    SitesForm,
    InputText,
    Select,
    Label,
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      items: [],
      selectedItems: [],
      isResetForm: false,
      editedItem: {},
      isNewItem: false,
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
      },
      popups: {
        isShowItemForm: false,
        isShowRemoveDialog: false,
      },
      searchInputs: {
        word: "",
      },
      keyButton: false,
    };
  },

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });
    Store.dispatch("GlobalHeader/setSite", {
      field_id: null,
    });
    this.getItems();

    /**
     * (Common)
     * Get data and pagination
     */

    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        this.items = data[0];
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters["File/getData"]],
      (data) => {
        if (data && data[0]) {
          const url = window.URL.createObjectURL(new Blob([data[0]]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Tnk_Field_Qr.pdf");
          document.body.appendChild(link);
          link.click();
          Store.dispatch("File/clearData");
        }
      },
      {
        deep: true,
      }
    );
  },

  computed: {
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return (
        this.selectedItems.length === 0 ||
        this.companyType === COMPANY_TYPE.PATNER ||
        this.companyType === COMPANY_TYPE.RENTAL
      );
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        name: this.searchParams.word,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },

    /**
     * Info company_type of user login
     */
    companyType() {
      return Store.getters[`Login/getUser`]?.company_type;
    },

    /**
     * set condition disabled button add
     */
    disableBtnAdd() {
      if (this.companyType === COMPANY_TYPE.ADMIN) return false;
      return true;
    },
  },

  methods: {
    getDetailAddress(item) {
      return `${item.prefecture} ${item.city} ${item.address}`;
    },
    /**
     *
     * pageUpdate
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    onSearch() {
      this.searchParams = { ...this.searchParams, ...this.searchInputs };
      this.searchParams["currentPage"] = 1;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },

    /**
     *
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },

    /**
     *
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * openItemForm
     * @param INITIAL_ITEMで定義している値
     */
    async openItemForm(item) {
      const siteInfo = await Store.dispatch(
        `${STORE}/getSitesDetail`,
        item.field_id
      );
      // Show Detail Form
      if (!siteInfo.hasError) {
        this.editedItem = { ...siteInfo.data.contents.entries };
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },

    //open new item form
    async openNewItemForm() {
      this.editedItem = { ...INITIAL_ITEM };
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },

    //close item form
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        this.editedItem = { ...INITIAL_ITEM };
      });
    },

    //open remove dialog form
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },

    //close remove dialog form
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    //update with new params
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    //get item by api params
    async getItems() {
      await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
    },

    //remove items with ids
    async removeItems() {
      const field_ids = this.selectedItems.map((items) => items.field_id);
      const result = await Store.dispatch(`${STORE}/delete`, { field_ids });
      if (!result.hasError) {
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
      this.closeRemoveDialog();
    },

    //submit form
    async submitForm() {
      const hasId = "id" in this.editedItem;
      const result = await Store.dispatch(
        hasId ? `${STORE}/update` : `${STORE}/add`,
        this.editedItem
      );
      if (result.hasError) {
        this.keyButton = !this.keyButton;
        return;
      } else if (hasId) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        const resultDetail = await Store.dispatch(
          `${STORE}/getSitesDetail`,
          this.editedItem.id
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isResetForm = !this.isResetForm;
      } else {
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
    },

    /**
     * Dowload site by qrcode
     */
    async dowloadQRCode(field_id) {
      let params = { field_id };

      const configParams = {
        url: DOWNLOAD_QR_CODE_URL,
        params: params,
      };
      await Store.dispatch("File/download", configParams);
    },
  },
};
</script>
<style lang="scss" scoped>
.labelSearchWidthCustom {
  width: 35% !important;
}
</style>
