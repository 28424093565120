<template>
  <div id="table-1">
    <template v-for="page in compRows">
      <div class="container-fluid page-item text-14">
        <div class="row">
          <div class="col-lg-6 p-10-0">
            <div class="rectangle-approval">{{ getPrimeApproved() }}</div>
            <div class="col-md-12 p-10-0">
              <h2>点検表/確認（承認）状況一覧</h2>
            </div>
            <div class="col-md-12 p-10-0 text-CompanyName">
              <h3>点検会社： {{ getCompanyName() }}</h3>
            </div>
          </div>

          <div class="col-lg-6 p-0">
            <div class="col-md-12 p-0 p-12">
              <table class="w-100 table-signal">
                <thead>
                  <tr>
                    <th class="text-bold p-3" colspan="10">元請押印欄</th>
                  </tr>
                </thead>
                <tbody class="h-signature">
                  <tr>
                    <td v-for="primeApproved in compPrimeApproved">
                      <div v-if="primeApproved">
                        <div class="round-cycle">
                          <span>{{ primeApproved.prime_approve_name }}</span>
                        </div>
                        <span>{{ primeApproved.prime_approve_date }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row mt-20">
          <div class="col-md-4"></div>
          <div class="col-md-8 p-0">
            <span class="mb-0 text-bold note-text">
              <i class="note-bg"></i> : 点検結果<span class="text-red">未</span
              >承認 / -：未使用（未点検）/レ：良好 /×：調整または要修理
              /〇：調整または補修
            </span>
          </div>
        </div>
        <div class="row mt-20">
          <div class="table">
            <table class="w-100 table-inspection-result">
              <thead>
                <tr>
                  <th colspan="3" class="cross"></th>
                  <th colspan="31">{{ getMonth() }}</th>
                </tr>
                <tr class="table-row-header">
                  <th class="text-bold text-l table-col-machinename">機械名</th>
                  <th class="text-bold text-l table-col-machinefieldname">管理名</th>
                  <th class="text-bold text-center table-col-acceptnumber">
                    持込機械受理番号
                  </th>
                  <th v-for="i in 31">{{ i }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in 20">
                  <th class="text-left">
                    {{
                      page.rows[i - 1]
                        ? relateText(page.rows[i - 1].machine_name)
                        : ""
                    }}
                  </th>
                  <th class="text-left">
                    {{
                      page.rows[i - 1] ? page.rows[i - 1].machine_field_name : ""
                    }}
                  </th>
                  <th class="text-left">
                    {{
                      page.rows[i - 1] ? page.rows[i - 1].acceptance_number : ""
                    }}
                  </th>
                  <th
                    :class="{ 'bg-yellow': customerStatus(page, i, item - 1) }"
                    v-for="item in 31"
                  >
                    {{
                      page.rows[i - 1]
                        ? page.rows[i - 1].check_status[item - 1]
                        : ""
                    }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <p class="mb-0 text-bold">
              点検会社は管理する点検済み機械の点検状況を確認・承認すること<br />確認が済んでいないものは速やかに確認すること
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TemplateGuildDance",
  props: {
    listInspectionApprovalStatus: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resize("round-cycle");
    });
  },
  computed: {
    compRows() {
      return this.listInspectionApprovalStatus[0].pages ?? [];
    },
    compPrimeApproved() {
      let listPrimeApproveNames =
        this.listInspectionApprovalStatus[0].prime_approved_names ?? [];
      let listPrimeApproveDates =
        this.listInspectionApprovalStatus[0].prime_approved_dates ?? [];
      let listPrimeApprove = [];
      if (listPrimeApproveNames.length > 0) {
        for (let i = 0; i < listPrimeApproveNames.length; i++) {
          const primeApprove = {
            prime_approve_name: listPrimeApproveNames[i],
            prime_approve_date: listPrimeApproveDates[i],
          };
          listPrimeApprove = [...listPrimeApprove, primeApprove];
        }
      }
      console.log(listPrimeApprove);
      return listPrimeApprove;
    },
    compPrimeApprovedDates() {
      return this.listInspectionApprovalStatus[0].prime_approved_dates ?? [];
    },
  },
  methods: {
    relateText(str, length = 30) {
      if (str.length <= length) {
        return str;
      }
      str = str.slice(0, length);
      return `${str}...`;
    },
    resize(className) {
      let els = document.querySelectorAll(`.${className}`);
      els.forEach((el) => {
        let span = el.querySelector("span");
        if (span.offsetHeight > el.offsetHeight) {
          let currentFontSize =
            Math.floor(el.offsetHeight / span.innerText.length) || 1;
          if (currentFontSize < 10) {
            span.style.fontSize = `10px`;
            span.style.transform = `scale(0.${currentFontSize})`;
          } else {
            span.style.fontSize = `${currentFontSize}px`;
          }
        }
      });
    },
    customerStatus(Page, i, key) {
      if (Page.rows[i - 1]) {
        if (Page.rows[i - 1].approve_status[key] == "0") {
          return true;
        }
      }
      return false;
    },
    getCompanyName() {
      return this.listInspectionApprovalStatus[0].company_name ?? "";
    },
    getPrimeApproved() {
      if (this.listInspectionApprovalStatus[0].prime_approved) {
        switch (this.listInspectionApprovalStatus[0].prime_approved) {
          case 1:
            return "確認/指導";
          case 2:
            return "承認";
          default:
            return "";
        }
      }
    },
    getMonth() {
      return this.listInspectionApprovalStatus[0].month ?? "";
    },
  },
};
</script>

<style type="text/css" scoped>
/* start css common */
.table tr {
  height: 25px;
}

.table th {
  font-weight: 400;
}

.text-red {
  color: red;
}

.text-bold {
  font-weight: bold;
}

.text-14 {
  font-size: 14px;
}

.image-container {
  display: flex;
  align-items: center;
}

.image-container .image-view {
  max-height: 360px;
  margin: 0 auto;
}

.p-12 {
  padding: 12px;
}

.p-text {
  padding: 10px;
}

.p-0 {
  padding: 0px;
}

.p-3 {
  padding: 3px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-auto {
  margin-left: auto;
}

.mr--15 {
  margin-right: -15px;
}

.ml--15 {
  margin-left: -15px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.p-10-0 {
  padding: 10px 0px;
}

.w-100 {
  width: 100%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.text-td {
  width: 150px;
  overflow: hidden;
}

.text-td span {
  white-space: nowrap;
  font-size: 10px;
}

.text-li {
  width: 220px;
  overflow: hidden;
}

.text-li span {
  white-space: nowrap;
  font-size: 12px;
}

.page-item {
  padding-bottom: 25px !important;
  border-bottom: 20px solid;
  border-color: rgba(111, 111, 111, 0.2) transparent;
}

.page-item:last-child {
  border: none !important;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 10px;
}

.scrollbar-x::-webkit-scrollbar {
  width: 6px;
  background-color: #f8f8f8;
  border-radius: 5px;
  height: 6px;
}

.scrollbar-x::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-fluid {
  padding: 0 30px;
  margin-top: 30px;
}
.container-fluid:first-child {
  padding: 0 30px;
  margin-top: 0px;
}

.container {
  width: 1000px;
  padding: 0 15px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (min-width: 768px) {
  .row.separate::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #000;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.p-under {
  text-decoration: underline;
}

table {
  border-collapse: collapse;
}

table td,
table th {
  border: 1px solid;
  min-width: 15px;
}

table .cross {
  background-image: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    #000,
    transparent calc(50% + 1px)
  );
}

.table {
  width: 100%;
  overflow: hidden;
}

img {
  max-width: 100%;
}

ol {
  padding-left: 20px;
  font-size: 80%;
}

.box {
  border: 1px solid;
  width: 100%;
  height: 130px;
}

.text-right {
  text-align: right;
}

.f-bold {
  font-weight: 700;
}

.ws-nowrap {
  white-space: nowrap;
}

/* table2 */
#table-1 {
  width: 297mm;
  margin: 0 auto;
}

#wrap .title {
  text-align: center;
}

#wrap .content {
  /* width: 1110px; */
  margin: auto;
}

#wrap .w-100 {
  width: 100%;
}

#wrap .text-end {
  text-align: end;
  margin-top: -5px;
}

#wrap .text-center {
  text-align: center !important;
}

#wrap .content ul {
  display: flex;
  gap: 300px;
  border: 1px solid;
}

#wrap .content ul li {
  list-style: none;
  position: relative;
}

#wrap .content ul li::after {
  content: "";
  background: black;
  width: 1px;
  height: 100%;
  position: absolute;
  right: -32px;
  transform: translateY(-50%);
  top: 50%;
}

#wrap .content ul li::before {
  content: "";
  background: black;
  width: 1px;
  height: 100%;
  position: absolute;
  left: -32px;
  transform: translateY(-50%);
  top: 50%;
}

#wrap .content ul li:nth-child(1)::before {
  opacity: 0;
}

#wrap .content .titleContent {
  border: none;
  gap: 50px;
  font-weight: bold;
  padding: 0;
}

#wrap .content .titleContent li::after {
  display: none;
}

#wrap .content .titleContent li::before {
  display: none;
}

#warp .content table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

#warp .content .point {
  position: relative;
}

#wrap .point {
  position: relative;
  width: 300px;
}

#wrap .point {
  background-image: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    #000,
    transparent calc(50% + 1px)
  );
}

#wrap .point-1 {
  position: absolute;
  bottom: -15px;
}

#wrap .custom {
  display: block;
  width: 27px;
  text-align: center;
  margin: auto;
}

#wrap table {
  border-spacing: 0;
}

#wrap .footer-table {
  padding-top: 20px;
}

#wrap .footer-table-ul {
  border: none !important;
  display: block !important;
  padding: 0;
}

#wrap .footer-table-ul li::after {
  display: none;
}

#wrap .footer-table-ul li::before {
  display: none;
}
/* end css common */

.h-signature {
  height: 150px;
  border: solid 1px black;
}
.rectangle-approval {
  height: 50px;
  width: 80px;
  border: solid 2px black;
  float: right;
  position: relative;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.note-text {
  display: flex;
  font-size: 15px !important;
  width: fit-content !important;
}
.note-text .note-bg {
  width: 20px;
  height: 20px;
  background: yellow;
  margin-right: 3px;
}
.bg-yellow {
  background: yellow;
}
.table-col-machinename {
  width: 220px;
}
.table-col-machinefieldname {
  width: 170px;
}
.table-col-acceptnumber {
  width: 130px;
}
.table-inspection-result {
  font-size: 9pt;
}
.table-row-header {
  font-size: 12px !important;
  font-weight: 900;
  border-bottom: inset 2px black !important;
}

.table-inspection-result tbody tr {
  height: 20px;
}
.table-inspection-result tbody th {
  font-weight: bold;
  line-height: 0.9px;
  white-space: nowrap;
}
.table-signal tbody td {
  padding: 5px;
  text-align: center;
  border: solid 0px black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.table-signal tbody tr {
  display: flex;
  flex-wrap: wrap;
}
.table-signal tbody td > div {
  margin: 0px -1px 0px 0px;
}
.table-signal tbody td span {
  color: red;
}
.round-cycle {
  display: flex;
  height: 55px !important;
  width: 45px !important;
  border: solid 2px red;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-lr;
  text-orientation: upright;
  color: red;
  font-weight: bold;
  font-size: 1rem;
  overflow: hidden;
}
.round-cycle span {
  white-space: nowrap;
  padding: 0px;
}
.text-CompanyName {
  width: 431px;
}
</style>
