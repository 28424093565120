// COMPANY_TABLE_LABELS
export const COMPANY_TABLE_LABELS = [
  { text: "", value: "data-table-select", align: "center", width: "5%" },
  { text: "会社名", value: "name", align: "left", width: "20%" },
  { text: "", value: "company_type", align: "left", width: "10%" },
  { text: "住所", value: "address", align: "left", width: "20%" },
  { text: "電話番号", value: "tel", align: "left", width: "45%" },
];

// COMPANY_SORT_ITEMS
export const COMPANY_SORT_ITEMS = [
  { id: "name", name: "会社名" },
  { id: "address", name: "住所" },
  { id: "tel", name: "電話番号" },
];

/**
 * COMPANY_CONTRACT
 */
export const COMPANY_CONTRACT = {
  PRIME_CONTRACTOR: { id: 0, name: "元請" },
  PARTNER_COMPANY: { id: 2, name: "レンタル" },
  COMPANY_RENTAL: { id: 1, name: "協力会社" },
};

export const TITLE = {
  NEW: "会社情報",
  EDIT: "会社情報",
};

export const FORMS = {
  FormBasicInfo: { id: 1, title: "基本情報" },
};

export const COPORATE_PREFIX_ARR = [
  { id: 0, name: "（会社名）株式会社" },
  { id: 1, name: "株式会社（会社名）" },
  { id: 2, name: "（会社名）有限会社" },
  { id: 3, name: "有限会社（会社名）" },
  { id: 4, name: "（会社名）合名会社" },
  { id: 5, name: "合名会社（会社名）" },
  { id: 6, name: "（会社名）合資会社" },
  { id: 7, name: "合資会社（会社名）" },
  { id: 8, name: "（会社名）合同会社 " },
  { id: 9, name: "合同会社（会社名）" },
  { id: 10, name: "個人事業主（登記名or個人名）" },
];

export const COMPANY_TYPE_ARR = [
  { name: "元請", value: 0 },
  { name: "協力会社", value: 1 },
  { name: "レンタル会社", value: 2 },
];

export const COMPANY_INITAL_ITEM = {
  name: "",
  name_kana: "",
  company_type: 1,
  corporate_number: "",
  corporate_type: 0,
  postal_code: null,
  prefecture: null,
  city: "",
  address: "",
  tel: "",
  fax: "",
  is_required_corporate_number: true,
};

export const COMPANY_TYPE_MAIN_CONTRACTOR = 0;
