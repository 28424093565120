<template>
  <v-dialog
    v-model="isActive"
    max-width="290"
    :no-click-animation="true"
    :persistent="true"
  >
    <v-card>
      <v-card-title class="text-h6"> タイムアウトしました </v-card-title>
      <v-card-text> 再度ログインをしてください。 </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onReload"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Store } from "@/store/Store.js";
import { LOGIN_FLAG_KEY } from "@/constants/LOGIN";
import { STORAGE_KEY } from "@/constants/GLOBALHEADER";

export default {
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    this.$watch(
      () => Store.getters["Error/getError"],
      (error) => {
        const { tokenError } = error;
        this.isActive = tokenError;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    // リロード
    onReload() {
      // local storageにフラグを削除
      localStorage.removeItem(LOGIN_FLAG_KEY);
      sessionStorage.removeItem("COMPANY_USER");
      sessionStorage.removeItem("GRN");
      sessionStorage.removeItem("GRN_HEADERS");
      sessionStorage.removeItem(STORAGE_KEY);
      window.location.href = "/";
      window.location.reload(true);
    },
  },
};
</script>
