<template class>
  <div ref="self" class="inspection-result">
    <v-toolbar flat>
      <v-toolbar-title class="worksHeader-title" v-if="isTitle">
        {{ pageTitle }}
      </v-toolbar-title>
      <div
        v-if="isGuidanceMode || isReportMonth"
        class="mx-3"
        :values="searchParams"
        small
        icon
      >
        <v-icon>mdi-calendar-today</v-icon>
      </div>
      <div v-if="isGuidanceMode" class="inputDatepicker mt-5 mr-3">
        <InputDateMonthPicker
          name="lead_approved_month"
          :editable="editable"
          :values="searchParams"
          @onInput="onChangeSearchParams"
        />
      </div>
      <div
        v-if="!isGuidanceMode && !isReportMonth"
        class="inputDatepickerCustom mt-5 mr-3"
      >
        <div id="pin-calendar" class="calendar-pick-date">
          <v-icon class="icon-calendar"> mdi-calendar-today </v-icon>
        </div>
        <span class="space"></span>
        <InputDatepickerCustom
          pinIdSpecial="pin-calendar"
          name="inspection_date_custom"
          :editable="editable"
          :values="searchParams"
          @onInput="onChangeSearchParams"
        />
        <div class="pin-pick-date">
          <div class="button-group">
            <v-btn
              class="ma-3 prev"
              outlined
              color="success"
              @click="setDateByType(3)"
            >
              <v-icon color="primary"> mdi-chevron-left</v-icon>
              <span class="mg-c">前日</span>
            </v-btn>
            <v-btn class="ma-3" color="primary" @click="setDateByType(1)">
              <span class="ct-b">今日</span>
            </v-btn>
            <v-btn
              class="ma-3 next"
              outlined
              color="success"
              @click="setDateByType(2)"
            >
              <span class="mg-c">翌日</span>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              class="ma-3 prev"
              outlined
              color="success"
              @click="setDateByType(6)"
            >
              <v-icon color="primary">mdi-chevron-left</v-icon>
              <span class="mg-c">前週</span>
            </v-btn>
            <v-btn class="ma-3" color="primary" @click="setDateByType(4)">
              <span class="ct-b">今週</span>
            </v-btn>
            <v-btn
              class="ma-3 next"
              outlined
              color="success"
              @click="setDateByType(5)"
            >
              <span class="mg-c">翌週</span>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              class="ma-3 prev"
              outlined
              color="success"
              @click="setDateByType(9)"
            >
              <v-icon color="primary">mdi-chevron-left</v-icon>
              <span class="mg-c">前月</span>
            </v-btn>
            <v-btn class="ma-3" color="primary" @click="setDateByType(7)">
              <span class="ct-b">今月</span>
            </v-btn>
            <v-btn
              class="ma-3 next"
              outlined
              color="success"
              @click="setDateByType(8)"
            >
              <span class="mg-c">翌月</span>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div v-if="isReportMonth" class="inputDatepicker mt-5 mr-3">
        <InputDateMonthPicker
          name="inspection_date_from"
          :editable="editable"
          :values="searchParams"
          @onInput="onChangeSearchParams"
        />
      </div>
      <span v-if="isReportMonth" style="font-size: 25px">~</span>
      <div v-if="isReportMonth" class="mx-3" :values="searchParams" small icon>
        <v-icon>mdi-calendar-today</v-icon>
      </div>
      <div v-if="isReportMonth" class="inputDatepicker mt-5">
        <InputDateMonthPicker
          name="inspection_date_to"
          :editable="editable"
          :values="searchParams"
          @onInput="onChangeSearchParams"
        />
      </div>

      <v-spacer></v-spacer>
      <!-- <v-btn
        class="mx-2 TableHeader__menu__icon"
        small
        icon
        @click="openSearch"
      >
        <v-icon class="icon_large"> mdi-magnify </v-icon>
      </v-btn> -->
      <v-btn
        class="mx-2 mr-5 TableHeader__menu__icon"
        :disabled="multiSelectStatus"
        small
        icon
        @click="openPrint"
      >
        <v-icon class="icon_large"> mdi-printer </v-icon>
      </v-btn>

      <v-btn
        v-if="!isGuidanceMode && !isTabDate2"
        class="ma-2 mr-5"
        depressed
        color="warning"
        :disabled="multiSelectStatus || this.isDisableBtnEditAprrove"
        @click="$emit('onReject')"
      >
        承認解除
      </v-btn>
      <v-btn
        v-if="!isGuidanceMode && !isTabDate2"
        depressed
        class="ma-2 mr-5"
        color="primary"
        :disabled="multiSelectStatus || this.isDisableBtnEditAprrove"
        @click="$emit('onApprove')"
      >
        承認
      </v-btn>
      <v-btn
        v-if="!isGuidanceMode && !isTabDate2"
        depressed
        :disabled="disableBtnAll || this.isDisableBtnEditAprrove"
        class="ma-2"
        color="primary"
        @click="$emit('onApproveAll')"
      >
        全件承認
      </v-btn>
      <v-btn
        v-if="isTabDate2 && isLoginUserMain"
        depressed
        class="ma-2"
        color="primary"
        @click="$emit('onGuidance')"
      >
        全件指導
      </v-btn>
    </v-toolbar>
    <div v-if="isShowSearch">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import InputDateMonthPicker from "@/components/forms/elements/InputDateMonthPicker";
import InputDatepickerCustom from "@/components/forms/elements/InputDatepickerCustom";
import { COMPANY_TYPE } from "@/constants/COMMON";
import { format } from "date-fns";
import {
  FLAG_RELATE_FIELD,
  FLAG_MACHINE_FIELD_MANAGER,
} from "@/constants/INSPECTION";
import { Store } from "@/store/Store.js";
export default {
  data: () => {
    return {
      isShowSearch: false,
      FLAG_RELATE_FIELD,
      FLAG_MACHINE_FIELD_MANAGER,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    multiSelectStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
    searchParams: {
      type: Object,
      defauly: null,
    },
    disableBtnAll: {
      type: Boolean,
      default: true,
    },
    isRelateField: {
      type: Number,
      default: FLAG_RELATE_FIELD.NOT_RELATE_FIELD,
    },
    isMachineFieldManager: {
      type: Number,
      default: FLAG_MACHINE_FIELD_MANAGER.NOT_FLAG,
    },
  },
  computed: {
    isGuidanceMode() {
      return this.searchParams?.inspection_frequency == 2 ? true : false;
    },
    isReportDay() {
      return this.searchParams?.inspection_frequency == 0 ? true : false;
    },
    isTabDate2() {
      return this.searchParams?.inspection_frequency === 3 ? true : false;
    },
    isReportMonth() {
      return this.searchParams?.inspection_frequency === 1 ? true : false;
    },
    USER_LOGIN() {
      return Store.getters[`Login/getUser`];
    },
    isLoginUserMain() {
      return this.USER_LOGIN.company_type == COMPANY_TYPE.OWNER;
    },
    isDisableBtnEditAprrove() {
      if (this.isReportDay) {
        return this.isRelateField == this.FLAG_RELATE_FIELD.NOT_RELATE_FIELD;
      } else {
        return (
          this.isMachineFieldManager == this.FLAG_MACHINE_FIELD_MANAGER.NOT_FLAG
        );
      }
    },
  },
  components: {
    InputDatepicker,
    InputDateMonthPicker,
    InputDatepickerCustom,
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);
  },
  methods: {
    onChangeSearchParams({ name, value }) {
      this.$emit("onInput", { name, value });
    },
    openSearch() {
      this.isShowSearch = !this.isShowSearch;
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
    openPrint() {
      this.$emit("openPrint");
    },
    setDateByType(type) {
      let fromDate;
      let toDate;
      let startDateSelect = new Date(this.searchParams.inspection_date_from);
      let endDateSelect = new Date(this.searchParams.inspection_date_to);
      let current = new Date();
      let now = format(new Date(Date.now()), "yyyy/MM/dd");
      switch (type) {
        // case of button 今日 (date now)
        case 1:
          fromDate = now;
          toDate = now;
          break;
        // case of button 翌日 (+ 1 day)
        case 2:
          startDateSelect.setDate(startDateSelect.getDate() + 1);
          fromDate = format(new Date(startDateSelect), "yyyy/MM/dd");
          toDate = format(new Date(startDateSelect), "yyyy/MM/dd");
          break;
        // case of button 前日 (- 1 day)
        case 3:
          startDateSelect.setDate(startDateSelect.getDate() - 1);
          fromDate = format(new Date(startDateSelect), "yyyy/MM/dd");
          toDate = format(new Date(startDateSelect), "yyyy/MM/dd");
          break;
        // case of button 今週 (week now)
        case 4:
          fromDate = new Date(
            current.setDate(current.getDate() - current.getDay())
          );
          toDate = new Date(
            current.setDate(current.getDate() - current.getDay() + 6)
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
        // case of button 翌週 (+ 1 week)
        case 5:
          startDateSelect.setDate(startDateSelect.getDate() + 7);
          fromDate = new Date(
            startDateSelect.setDate(
              startDateSelect.getDate() - startDateSelect.getDay()
            )
          );
          toDate = new Date(
            startDateSelect.setDate(
              startDateSelect.getDate() - startDateSelect.getDay() + 6
            )
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
        // case of button 前週 (- 1 week)
        case 6:
          startDateSelect.setDate(startDateSelect.getDate() - 7);
          fromDate = new Date(
            startDateSelect.setDate(
              startDateSelect.getDate() - startDateSelect.getDay()
            )
          );
          toDate = new Date(
            startDateSelect.setDate(
              startDateSelect.getDate() - startDateSelect.getDay() + 6
            )
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
        // case of button 今月 (month now)
        case 7:
          startDateSelect = new Date(
            current.getFullYear(),
            current.getMonth(),
            1
          );
          fromDate = format(startDateSelect, "yyyy-MM-dd");
          endDateSelect = new Date(
            current.getFullYear(),
            current.getMonth() + 1,
            0
          );
          toDate = format(endDateSelect, "yyyy-MM-dd");
          break;
        // case of button 翌月 (+ 1 month)
        case 8:
          startDateSelect.setMonth(startDateSelect.getMonth() + 1);
          fromDate = new Date(
            startDateSelect.getFullYear(),
            startDateSelect.getMonth(),
            1
          );
          toDate = new Date(
            startDateSelect.getFullYear(),
            startDateSelect.getMonth() + 1,
            0
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
        // case of button 前月 (- 1 month)
        case 9:
          startDateSelect.setMonth(startDateSelect.getMonth() - 1);
          fromDate = new Date(
            startDateSelect.getFullYear(),
            startDateSelect.getMonth(),
            1
          );
          toDate = new Date(
            startDateSelect.getFullYear(),
            startDateSelect.getMonth() + 1,
            0
          );
          fromDate = format(fromDate, "yyyy-MM-dd");
          toDate = format(toDate, "yyyy-MM-dd");
          break;
      }
      this.searchParams.inspection_date_from = fromDate;
      this.searchParams.inspection_date_to = toDate;
      this.$emit("onInput", {
        name: "customDate",
        value: { fromVal: fromDate, toVal: toDate },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/TableHeader.scss";
.v-toolbar__title.worksHeader-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.button-group {
  flex-wrap: wrap;
  position: absolute;
  left: -1px;
  margin-left: 0;
  background: #fff;
  box-shadow: 2px 4px 4px 2px rgb(0 0 0 / 25%);
  top: 40px;
  justify-content: space-between;
  border-radius: 8px;
  z-index: 9999;
  width: 400px;
}
.button-group button {
  flex: 0 0 90px;
  max-width: 90px;
}
.button-group button.prev,
.button-group button.next {
  flex: 0 0 113px;
  max-width: 113px;
}
.button-group {
  display: none;
}
.inputDatepickerCustom {
  display: flex;
}
.pin-pick-date:hover .button-group {
  display: flex;
}
.calendar-pick-date {
  margin-left: 12px !important;
  height: 40px;
  .icon-calendar {
    margin-bottom: -25px;
  }
}
.calendar-pick-date ::v-deep .v-menu__content {
  top: 52px !important;
  left: 110px !important;
  z-index: 8;
  display: none;
}
.calendar-pick-date:hover ::v-deep .v-menu__content {
  display: flex !important;
}
.space {
  width: 12px;
}
.pin-pick-date {
  width: 209px;
  height: 41px;
  position: absolute;
  left: 160px;
  z-index: 1;
}
</style>
