<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-tabs v-model="val">
          <v-tab v-for="item in items" :key="item.id" @click="onInput(item.id)">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="mr-8" cols="12" sm="2" md="2">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => {
    return {
      val: 0,
    };
  },
  props: {
    name: {
      type: String,
    },
    items: {
      type: Array,
    },
    values: {
      type: Object,
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
    this.$watch(() => this.val, {
      deep: true,
    });
  },
  methods: {
    onInput(id) {
      this.$emit("onInput", id);
    },
  },
};
</script>
