const SITE_INITIAL_ITEM = {
  name: null,
  postal_code: null,
  prefecture_id: null,
  city: null,
  address: null,
  company_id: null,
  machine_manager_id: null,
  report_checking_day: 1,
  contact_company_id: null,
  contact_user_id: null,
  contact_phone: null,
  report_checking_flg: 1,
};
const SITE_TABLE_LABELS = [
  {
    text: "",
    value: "data-table-select",
    align: "center",
    width: "5%",
  },
  {
    text: "現場名",
    value: "name",
    align: "left",
    width: "30%",
  },
  {
    text: "住所",
    value: "address",
    align: "left",
    width: "55%",
  },
  {
    text: "",
    value: "dowload_qr",
    align: "left",
    width: "10%",
  },
];
const SITE_SORT_ITEMS = [
  { id: "name", name: "現場名" },
  { id: "address", name: "住所" },
];
const PREFECTURES = [
  { id: 1, name: "HaNoi" },
  { id: 2, name: "ThaiBinh" },
];
const SITE_TITLE = "登録現場一覧";

const getDay = () => {
  let days = [];
  let i = 1;
  while (i <= 25) {
    days.push({ id: i, name: `${i}日` });
    i++;
  }
  return days;
};
export const DAYS = getDay();

export {
  SITE_INITIAL_ITEM,
  SITE_TABLE_LABELS,
  SITE_SORT_ITEMS,
  PREFECTURES,
  SITE_TITLE,
};
