<template>
  <v-card class="tab-one-content">
    <div class="body-tab">
      <div class="left">
        <button class="btn-upload">
          <label for="formFileLg">ファイルを選択</label>
          <input
            class="d-none"
            type="file"
            accept="image/gif, image/jpeg, image/png"
            ref="fileInput"
            id="formFileLg"
            @input="selectImgFile"
          />
        </button>
      </div>
      <div class="right img-bg d-flex">
        <img v-bind:src="urlFile" alt="" class="w-img" />
        <div class="bg-loading" v-if="isLoading">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { Store } from "@/store/Store.js";
const TYPE_FILE = {
  gif: "image/gif",
  jpeg: "image/jpeg",
  png: "image/png",
};
export default {
  name: "TabOneContent",
  data() {
    return {
      enable: false,
      fileBase64: null,
      fileName: null,
      urlFile: null,
      beUrl: null,
      isLoading: false,
    };
  },
  props: {
    filePreview: {
      type: String,
      default: null,
    },
    isTemp: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let beUrl = process.env.VUE_APP_API_BASE_URL;
    let currentIndex = beUrl.lastIndexOf("/api");
    this.beUrl = beUrl.substring(0, currentIndex);
    this.$watch(
      () => [this.isTemp, this.filePreview],
      (newValue) => {
        if (newValue[0]) {
          this.urlFile = newValue[1];
        } else {
          this.urlFile = this.beUrl + newValue[1];
        }
      },
      { immediate: true, deep: true }
    );
  },
  watch: {
    getFilePreview() {
      this.urlFile = this.beUrl + this.filePreview;
    },
  },
  computed: {
    hasImage() {
      return this.fileBase64 != null;
    },
    getFilePreview() {
      return this.filePreview;
    },
  },
  methods: {
    selectImgFile() {
      let fileInput = this.$refs.fileInput;
      let imgFile = fileInput.files;
      this.fileName = imgFile[0]["name"];
      let isVai = this.validate(imgFile[0]);
      if (imgFile && imgFile[0] && isVai) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.fileBase64 = e.target.result;
          this.urlFile = e.target.result;
          this.$emit("changeFile", this.hasImage);
        };
        reader.readAsDataURL(imgFile[0]);
      }
    },
    validate(file) {
      let _10m = 10000000;
      if (
        file.type === TYPE_FILE.gif ||
        file.type === TYPE_FILE.jpeg ||
        file.type === TYPE_FILE.png
      ) {
        this.enable = false;
      } else {
        Store.dispatch(
          "Error/show",
          {
            status: "500",
            message: "jpg, png, jpegファイルのみアップロード可能です",
          },
          { root: true }
        );
        return false;
      }
      if (file.size > _10m) {
        this.enable = true;
        this.urlFile = null;
        Store.dispatch(
          "Error/show",
          { status: "500", message: "データ容量は10MB以下でお願いします" },
          { root: true }
        );
        return false;
      } else {
        this.enable = false;
      }
      return true;
    },
    cleanImage() {
      this.$refs.fileInput.value = null;
    },
    getInfoFile() {
      return {
        fileName: this.fileName,
        fileBase64: this.fileBase64,
      };
    },
    getValidateStatus() {
      return this.enable;
    },
    setLoading(bl) {
      this.isLoading = bl;
    },
  },
};
</script>

<style type="text/css" scoped>
.tab-one-content {
  padding-left: 20px;
  box-shadow: none !important;
  margin-top: 5px;
  height: auto;
  overflow: auto;
  padding-bottom: 80px;
}
.tab-one-content::-webkit-scrollbar {
  width: 6px !important;
  background-color: #f8f8f8 !important;
  border-radius: 5px !important;
}
.tab-one-content::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 10px;
}
.header-tab p {
  text-align: right;
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}
.body-tab {
  display: flex;
  height: calc(100% - 100px);
}
.left {
  width: 30%;
}
.right {
  width: 70%;
  position: relative;
}
.img-bg {
  width: 388px;
  height: auto;
  background-size: contain;
}
.btn-upload {
  background: #00c3ac;
  border-radius: 4px;
  color: #fff;
  overflow: hidden;
}
.btn-upload label {
  padding: 10px 14px;
  display: block;
  cursor: pointer;
}
.w-img {
  max-height: 350px;
  margin: 0 auto;
}
.bg-loading {
  opacity: 0.5;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #00c3ac;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
