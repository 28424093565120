import Api, { Mock } from "../api";

const PATH_WEB = "reset_request_web";
const URL_WEB = `${process.env.VUE_APP_API_BASE_URL}/${PATH_WEB}`;

if (Mock) {
  Mock.onPost(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const resetMailWeb = {
  post: (params) => {
    return Api.post(URL_WEB, params);
  },
};
