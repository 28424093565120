<template>
  <v-card class="dialog">
    <v-card-title>
      <h1 class="title-header">{{ titleHeader }}</h1>
    </v-card-title>
    <v-card-text class="body">
      <slot name="form-data" class="form-data" />
      <div class="action">
        <div class="delete">
          <v-btn
            v-if="isDelete"
            class="mx-2 TableHeader__menu__icon box-shadow-none"
            small
            icon
            :disabled="disableDelete"
            @click="$emit('delete')"
          >
            <v-icon> mdi-delete-outline </v-icon>
          </v-btn>
        </div>
        <div class="button-group">
          <v-btn class="btn-cancel box-shadow-none" @click="$emit('clone')">
            {{ DIALOG_BUTTON_NAMES.CANCEL }}
          </v-btn>
          <v-btn
            @click="$emit('submit')"
            class="btn-submit box-shadow-none"
            type="submit"
          >
            {{ textBtn.btnSubmit }}
          </v-btn>
          <v-btn
            v-if="isBtnContinue"
            @click="$emit('continue')"
            class="btn-submit ml-4 box-shadow-none"
          >
            {{ DIALOG_BUTTON_NAMES.CONTINUE }}
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { DIALOG_BUTTON_NAMES } from "@/constants/COMMON";
export default {
  name: "AddDataSettingMachine",
  components: {},
  data: () => ({ DIALOG_BUTTON_NAMES }),
  props: {
    titleHeader: {
      type: String,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    isBtnContinue: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    textBtn: {
      btnSubmit: "保存",
    },
  },
};
</script>

<style type="scss" scoped>
.dialog {
  height: auto !important;
}
.FormDialog {
  width: 480px;
  height: 300px;
  margin: 0 auto;
  overflow: hidden;
}
.title-header {
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 21px 28px 0px 15px;
}
.action {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  .button-group {
    display: flex;
  }
}
.btn-submit {
  color: #fff !important;
  background: #00c3ac !important;
}
.btn-cancel {
  margin-right: 20px;
  background: #fff !important;
  color: #00c3ac;
  border: solid 1px #00c3ac;
}
.box-shadow-none {
  box-shadow: none !important;
}
</style>
