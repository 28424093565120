import Api, { Mock } from "../api";
import mock_sites from "./mock/sites.json";
import mock_sites_detail from "./mock/sites_detail.json";
import mock_company from "./mock/company_by_filed_id.json";
import mock_list_company from "./mock/company_list_by_filed_id.json";

const URL = `${process.env.VUE_APP_API_BASE_URL}/fields`;
const GET_LIST_URL = `${URL}/get_list_fields`;
const DELETE_URL = `${URL}/delete_fields`;
const GET_DETAIL_SITE_URL = `${URL}/get_field_info`;
const CREATE_SITE_URL = `${URL}/add_field_info`;
const UPDATE_SITE_URL = `${URL}/update_field_info`;
const GET_LIST_COMPANY_BY_FIELD = `${URL}/get_list_company_fields`;
const GET_LIST_COMPANY_BY_FIELD2 = `${URL}/get_list_company_inspector`;

if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_sites,
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_SITE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_sites_detail,
  });
  Mock.onGet(GET_LIST_COMPANY_BY_FIELD).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_company,
  });
  Mock.onGet(new RegExp(`${GET_LIST_COMPANY_BY_FIELD2}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_list_company,
  });
  Mock.onPost(CREATE_SITE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_SITE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const sites = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getSitesDetail: async (params) => {
    return Api.get(`${GET_DETAIL_SITE_URL}/${params}`);
  },
  add: (params) => {
    return Api.post(CREATE_SITE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_SITE_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getCompanyByField: async (params) => {
    return Api.get(GET_LIST_COMPANY_BY_FIELD, { params });
  },
  getListCompanyByField: async (params) => {
    return Api.get(`${GET_LIST_COMPANY_BY_FIELD2}/${params}`);
  },
};
