<template>
  <v-card class="dialog">
    <v-card-title>
      <img class="logo" src="@/assets/rakuraku.png" />
      <div :style="getTitleColorStyle" class="title">システムアップデート</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div :style="getTextColorStyle" class="text">
          下記の日程でアップデートを行います。
        </div>
        <div class="maintain-text-wrap">
          <div :style="getMaintainColorStyle" class="maintain-text">
            {{ date }}
          </div>
          <div :style="getMaintainColorStyle" class="maintain-text">
            {{ time }}
          </div>
        </div>
        <div :style="getTextColorStyle" class="text">
          上記の時間帯でシステムが利用できなくなります。
        </div>
      </div>
      <hr style="border-top: dashed 2px; color: #083874" />
      <br />
      <div class="text-wrap">
        <div :style="getTextColorStyle" class="text">内容</div>
        <div :style="getNoticeColorStyle" class="text-area">{{ message }}</div>
        <v-checkbox
          :label="checkbox.label"
          :value="checkbox.value"
          v-model="isNotShowStatus"
        ></v-checkbox>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        depressed
        outlined
        class="btn"
        @click="$emit('close', isNotShowStatus)"
      >
        OK
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    date: {
      type: String,
    },
    time: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  computed: {
    getTitleColorStyle() {
      return "color:#083874 !important;";
    },
    getTextColorStyle() {
      return "color:#466A97 !important;";
    },
    getMaintainColorStyle() {
      return "color:#FF2020 !important;";
    },
    getNoticeColorStyle() {
      return "color:#2F2F2F !important;";
    },
    getCheckboxTextColorStyle() {
      return "color:#606162 !important;";
    },
    getColor() {
      return "#FFFFFF";
    },
    getBackGroundColor() {
      return "#00C3AC";
    },
  },
  data: () => {
    return {
      isNotShowStatus: false,

      checkbox: {
        label: "次回以降表示しない",
        value: false,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;

  .logo {
    padding-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  .title {
    font-size: 25px !important;
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: center;
  }
  .body {
    padding-bottom: 0px !important;
    margin-bottom: -30px !important;
    .text-wrap {
      margin: auto;
      width: 60%;
      padding-bottom: 10px;
      .text {
        font-size: 15px !important;
      }
      .maintain-text-wrap {
        padding-top: 10px;
        padding-bottom: 10px;
        .maintain-text {
          font-size: 15px !important;
        }
      }
      .text-area {
        padding-top: 10px;
        padding-left: 20px;
        word-break: break-word;
        white-space: pre-wrap;
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }
  .btn {
    color: #ffffff !important;
    background: #00c3ac !important;
    margin: auto;
    width: 40%;
  }
}
</style>
