<template>
  <div>
    <div class="sticky">
      <v-row no-gutters>
        <v-col cols="12" sm="10" class="pa-3">
          <!-- 検索ワード -->
          <SearchInputText
            name="keyword"
            :values="formValues"
            placeholder="検索"
            validation_label="検索ワード"
            validation_rules="max:25"
            :editable="true"
            :clearable="true"
            prependInnerIcon="mdi-magnify"
            @onInput="onInput"
          />
        </v-col>
        <v-col cols="12" sm="2" class="pt-3 pr-2">
          <!-- 検索ボタン -->
          <v-btn @click="$emit('search')" color="primary">検索</v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- リスト -->
    <slot></slot>
  </div>
</template>

<script>
import SearchInputText from "@/components/forms/elements/SearchInputText";

export default {
  data() {
    return {
      values: {
        // 検索ワード
        search: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    };
  },

  components: {
    SearchInputText,
  },

  props: {
    formValues: Object,
    search: Function,
  },

  async mounted() {
    // formに表示する値を更新
    this.$watch(
      () => this.formValues,
      (newValue) => {
        if (newValue) this.values = newValue;
      },
      { immediate: true, deep: true }
    );
  },

  methods: {
    // idの更新
    onClick(id) {
      this.values.selected = id;
      this.$emit("formUpdate", this.values);
    },

    onInput({ name, value }) {
      this.values[name] = value;
      this.$emit("formUpdate", this.values);
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 65px;
  z-index: 1;
  background-color: white;
}
</style>
