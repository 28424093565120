<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      :name="validation_label"
      :rules="validation_rules"
    >
      <v-radio-group
        v-model="val"
        @change="$emit('onInput', { name, value: val })"
        :disabled="!editable"
        :error-messages="errors"
      >
        <v-radio
          v-for="item in items"
          :key="item.id"
          :label="item.name"
          :value="item.value"
        ></v-radio>
      </v-radio-group>
    </ValidationProvider>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    items: {
      type: Array,
    },
    values: {
      type: Object,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
  },
  data: () => {
    return {
      val: null,
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
<style lang="scss" scoped>
.v-radio {
  margin-left: 6px;
}
</style>
