<template>
  <v-card style="overflow-y: hidden; height: 750px">
    <div>
      <v-toolbar flat>
        <div style="color: green; font-size: 20px">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2"
          small
          icon
          :disabled="disabledButtonDelete"
          @click="confirmDeleteInspector()"
        >
          <v-icon v-if="inspection_frequency != 3">mdi-delete</v-icon>
        </v-btn>
        <v-btn
          v-if="inspection_frequency != 3"
          color="warning"
          @click="rejectConfirm"
          :disabled="!isShowButtonReject || this.isDisableBtnEditAprrove"
        >
          承認解除
        </v-btn>
        <v-btn
          v-if="inspection_frequency != 3"
          color="primary"
          class="ml-4"
          @click="approveConfirm"
          :disabled="!isShowButtonApprove || this.isDisableBtnEditAprrove"
        >
          承認
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-toolbar flat>
        <h4>{{ CHECK_STATUS_MESSAGE.INSPECTION1 }}</h4>
      </v-toolbar>

      <v-data-table
        style="padding-top: 10px"
        v-model="selectedItems"
        :headers="
          inspection_frequency != 3
            ? INSPECTION_FORM1_LABELS
            : INSPECTION_FORM1_LABELS_OTHER_COMPANY
        "
        :items="dataTable"
        hide-default-footer
        disable-sort
        fixed-header
        height="612px"
        :items-per-page="dataTable.length"
        class="elevation-0 v-data-table__wrapper"
        :show-select="inspection_frequency === 3 ? false : true"
        noDataText="データがありません。"
        outlined
        @click:row="openItemForm"
      >
        <template v-slot:[`item.approved_status`]="{ item }">
          <div>
            <v-chip
              medium
              :color="item.approved_status == 0 ? 'orange' : 'success'"
              >{{ item.approved_status == 0 ? "未承認" : "承認済" }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.result`]="{ item }">
          <div style="font-size: 25px">
            {{ handleResult(item.result) }}
          </div>
        </template>
        <template v-slot:[`item.inspection_at`]="{ item }">
          <div>
            {{ convertFormatDate(item) }}
          </div>
        </template>
      </v-data-table>
    </div>
    <Popup
      v-if="inspection_frequency != 3 || company_type == 0"
      :dialog="popups.isShowInspecForm2"
      width="1600px"
    >
      <InspectionForm2
        :item="editedItem"
        :isRelateField="isRelateField"
        :isDisableBtnEditAprrove="isDisableBtnEditAprrove"
        :inspection_frequency="inspection_frequency"
        :correctionItemDetail="inspection_result_correction_item"
        :imgIndex="imgIndex"
        :imageUrl="imageUrl"
        @cancel="closeItemForm2"
        @rejectResultDetail="changeApproveResultDetail"
        @approveResultDetail="changeApproveResultDetail"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowDialog">
      <ConfirmDialog
        @close="closeDialog"
        @yes="actionDialog"
        :isUnlock="true"
        :title="popups.titleDialog"
        :text="popups.messageDialog"
      />
    </Popup>

    <Popup width="500px" :dialog="isShowConfirmDialog">
      <ConfirmDialog
        @close="isShowConfirmDialog = false"
        @yes="closeForm"
        :isClose="true"
        :isCenter="false"
        title="フォームを閉じる確認"
        text="フォームを閉じますがよろしいですか？
        入力内容は保存されません。

        このページから移動してもよろしいですか？"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowUpdateErrorDialog">
      <ConfirmDialog
        @close="closeDialogUpdateError"
        :hasDetails="false"
        :isTwoButton="false"
        :isCenter="false"
        title="承認不可"
        text="自社が点検会社に設定されていない、点検実績も含まれています。"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowDialogErrorDelete">
      <ConfirmDialog
        @close="closeDeleteErrorDialog"
        :hasDetails="false"
        :isTwoButton="false"
        :title="popups.titleDialog"
        :text="popups.messageDialog"
      />
    </Popup>
    <Popup width="500px" :dialog="popups.isShowDialogConfirmDelete">
      <ConfirmDialog
        @close="closeDeleteDialog"
        @yes="deleteInspector"
        :isDelete="true"
        :hasDetails="false"
        :title="popups.titleDialog"
        :text="popups.messageDialog"
      />
    </Popup>
  </v-card>
</template>
<script>
import Popup from "@/components/common/Popup.vue";
import InspectionForm2 from "./InspectionForm2.vue";
import { Store } from "@/store/Store.js";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import _ from "lodash";
import {
  INSPECTION_FORM1_LABELS,
  ATTRIBUTE_DIALOG_ARRRAY,
  INSPECTION_RESULT_CATEGORY,
  RESULT_STATUS,
  CHECK_STATUS_MESSAGE,
  INFO_DELETE_CONFIRM_DIALOG,
  APPROVE_STATUS,
  INSPECTION_FORM1_LABELS_OTHER_COMPANY,
} from "@/constants/INSPECTION";
import {
  FLAG_RELATE_FIELD,
  FLAG_MACHINE_FIELD_MANAGER,
} from "@/constants/INSPECTION";
import { format } from "date-fns";

const STORE = "Inspections";
const URL = `${process.env.VUE_APP_API_BASE_URL}/file/read_file/`;
export default {
  data() {
    return {
      valid: false,
      tab: null,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      dataTable: [],
      INSPECTION_FORM1_LABELS,
      INSPECTION_RESULT_CATEGORY,
      INFO_DELETE_CONFIRM_DIALOG,
      APPROVE_STATUS,
      selectedItems: [],
      inspection_result_correction_item: {},
      imgIndex: null,
      imageUrl: "",
      popups: {
        titleDialog: "",
        messageDialog: "",
        message2Dialog: "",
        btnOkDialog: "",
        nameAction: "",
        isShowInspecForm2: false,
        isShowDialog: false,
        isShowUpdateErrorDialog: false,
        isShowDialogErrorDelete: false,
        isShowDialogConfirmDelete: false,
      },
      CHECK_STATUS_MESSAGE,
      editedItem: {},
      FLAG_RELATE_FIELD,
      FLAG_MACHINE_FIELD_MANAGER,
      INSPECTION_FORM1_LABELS_OTHER_COMPANY,
    };
  },
  components: {
    Popup,
    InspectionForm2,
    ConfirmDialog,
  },
  props: {
    item: Object,
    isRelateField: Number,
    isMachineFieldManager: Number,
    isReportDay: Boolean,
    inspection_frequency: Number,
    company_type: Number,
    isDialogComfirm: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.dataTable = [...data?.inspection_results];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    title() {
      const textCenter = "　/　";
      return `${
        this.item.middle_classification ? this.item.middle_classification : ""
      }
      ${
        this.item.machine_name ? `${textCenter} ${this.item.machine_name}` : ""
      } 
      ${
        this.item.machine_field_name
          ? `${textCenter} ${this.item.machine_field_name}`
          : ""
      }`;
    },

    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    isShowButtonApprove() {
      return this.selectedItems.length > 0;
    },
    isShowButtonReject() {
      return this.selectedItems.length > 0;
    },
    disabledButtonDelete() {
      return this.selectedItems.length == 0;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    isDisableBtnEditAprrove() {
      if (this.isReportDay) {
        return this.isRelateField !== this.FLAG_RELATE_FIELD.IS_RELATE_FIELD;
      } else {
        return (
          this.isMachineFieldManager !== this.FLAG_MACHINE_FIELD_MANAGER.IS_FLAG
        );
      }
    },
  },
  methods: {
    changeApproveResultDetail(id, approved_status) {
      this.dataTable.forEach((e) => {
        if (e.id == id) {
          e.approved_status = approved_status;
        }
      });
      this.popups.isShowInspecForm2 = false;
      this.$emit("getItems");
    },

    closeForm() {
      if (!this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
      } else {
        this.$emit("cancel");
      }
    },
    // close item form 2
    async closeItemForm2() {
      this.popups.isShowInspecForm2 = false;
    },
    // open item form 2
    async openItemForm(item) {
      let id = item.id;
      const rs = await Store.dispatch(`${STORE}/getResultDetail`, { id });
      if (!rs.hasError) {
        this.editedItem = _.cloneDeep(rs.data.contents.entries);
        let correction = this.editedItem.inspection_result_corrections;
        if (correction.length) {
          this.inspection_result_correction_item =
            correction[correction.length - 1];
          if (this.inspection_result_correction_item.correction_images.length) {
            if (this.imgIndex === null) this.imgIndex = 0;
            this.imageUrl =
              URL +
              this.inspection_result_correction_item.correction_images[
                this.imgIndex
              ].image;
          }
        } else {
          this.inspection_result_correction_item = {};
          this.imageUrl = null;
        }
        this.popups.isShowInspecForm2 = true;
      } else {
        const { data } = rs.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    // show dialog confirm close
    onClickBtnClose() {
      this.isClickCloseBtn = true;
      if (!this.isDialogComfirm) {
        this.$emit("cancel");
      }
      this.isShowConfirmDialog = this.isDialogComfirm;
    },
    // close dialog approval
    closeDialog() {
      this.popups.isShowDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    // call approval items or reject item
    actionDialog() {
      let action = this.popups.nameAction;
      if (action == INSPECTION_RESULT_CATEGORY.REJECT_APPROVE) {
        this.changeApproveItems(0);
      } else {
        this.changeApproveItems(1);
      }
    },

    /**
     * change approve items
     */
    async changeApproveItems(approved_status) {
      const ids = this.selectedItems.map((items) => items.id);
      if (ids == null || ids.length == 0) return;
      const result = await Store.dispatch(`${STORE}/updateStatusByGroup`, {
        ids,
        approved_status,
        inspection_frequency: this.inspection_frequency,
      });
      // success
      if (!result.hasError) {
        if (result.data.contents.entries.update_status) {
          this.dataTable.forEach((e) => {
            if (ids.includes(e.id)) {
              e.approved_status = approved_status;
            }
          });
          this.closeDialog();
          this.$emit("getItems");
          Store.dispatch("Toast/show", {
            status: 200,
            message: "登録しました",
          });
        } else {
          this.closeDialog();
          this.popups.isShowUpdateErrorDialog = true;
        }
      } else {
        // error
        this.closeDialog();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    // confirm reject item
    rejectConfirm() {
      this.popups.isShowDialog = true;
      this.popups.messageDialog =
        ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.REJECT_APPROVE;
    },
    // confirm approve item
    approveConfirm() {
      this.popups.isShowDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.APPROVE_ITEMS;
    },
    // convert date from yyyy-mm-dd to yyyy/mm/dd
    convertFormatDate(item) {
      return format(new Date(item.inspection_at), "yyyy/MM/dd HH:mm:ss");
    },
    handleResult(result) {
      let rs = "×";
      if (result == RESULT_STATUS.USE_AFTER_REPAIR) {
        rs = "●";
      } else if (result == RESULT_STATUS.GOOD) {
        rs = "レ";
      }
      return rs;
    },
    closeDialogUpdateError() {
      this.popups.isShowUpdateErrorDialog = false;
    },
    confirmDeleteInspector() {
      // check all selected is approved
      const isAllApproved = !this.selectedItems.find(
        (e) => e.approved_status === APPROVE_STATUS.UNAPPROVE.id
      );
      // if all record is approved then show dialog error
      if (isAllApproved) {
        this.popups.isShowDialogErrorDelete = true;
        this.popups.titleDialog =
          INFO_DELETE_CONFIRM_DIALOG.INFO_DELETE_ERROR.title;
        this.popups.messageDialog =
          INFO_DELETE_CONFIRM_DIALOG.INFO_DELETE_ERROR.message;
      } else {
        this.popups.isShowDialogConfirmDelete = true;
        this.popups.titleDialog =
          INFO_DELETE_CONFIRM_DIALOG.INFO_CONFIRM_DELETE.title;
        this.popups.messageDialog =
          INFO_DELETE_CONFIRM_DIALOG.INFO_CONFIRM_DELETE.message;
      }
    },
    closeDeleteErrorDialog() {
      this.selectedItems = [];
      this.popups.isShowDialogErrorDelete = false;
    },
    async deleteInspector() {
      const ids = this.selectedItems.map((item) => item.id);
      const params = {
        ids,
        field_id: this.CURRENT_SITE.field_id,
      };
      const result = await Store.dispatch(`${STORE}/deleteInspector`, params);
      this.closeDeleteDialog();
      if (!result.hasError) {
        await this.loadItemsDetail();
        this.$emit("getItems");
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    closeDeleteDialog() {
      this.selectedItems = [];
      this.popups.isShowDialogConfirmDelete = false;
    },
    async loadItemsDetail() {
      let ids = this.item.ids;
      const result = await Store.dispatch(`${STORE}/getByIds`, { ids });
      if (!result.hasError) {
        const items = _.cloneDeep(result.data.contents.entries);
        this.dataTable = items?.inspection_results || [];
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.worker-edit-table-wrapper.site-worker-edit {
  border-left: none;
}
::v-deep .header-custom {
  width: 157px !important;
  display: revert;
  margin-left: 12px;
  align-items: center;
  span {
    width: 57px !important;
    display: inline-flex !important;
    margin-right: 8px;
  }
}
</style>
