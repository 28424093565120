<template>
  <div ref="self" class="tableLayout">
    <div
      v-show="!hideHeader"
      ref="tableHeader"
      class="tableHeader"
      :class="hideHeader ? 'hide' : ''"
    >
      <slot name="tableHeader" :updateHeader="headerParams" />
    </div>
    <div ref="tableBody" class="tableBody">
      <slot name="tableBody" :tableHeight="tableHeight" />
    </div>
    <div
      v-show="!hideFooter"
      ref="tableFooter"
      class="tableFooter"
      :class="hideFooter ? 'hide' : ''"
    >
      <slot name="tableFooter" />
    </div>
  </div>
</template>
<script>
const heightElement = {
  sortWrapper: 32,
  pagiNation: 42,
};

export default {
  data: () => {
    return {
      tableHeight: 0,
      heightElement,
      headerParams: {
        openSearch: null,
      },
      onResizeEvent: null,
    };
  },
  props: {
    layoutParams: {
      type: Object,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.headerParams.setHeaderHeight = this.updateLayoutStatus;
    window.addEventListener("resize", this.onChangeWindowSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onChangeWindowSize);
  },
  methods: {
    onChangeWindowSize() {
      clearTimeout(this.onResizeEvent);
      this.onResizeEvent = setTimeout(() => {
        this.updateLayoutStatus();
      }, 300);
    },
    /**
     * レイアウトの再計算
     */
    updateLayoutStatus() {
      const tableHeaderRect = this.$refs.tableHeader.getBoundingClientRect();
      const tableFooterRect = this.$refs.tableFooter.getBoundingClientRect();
      const pageHeight = this.layoutParams.pageHeight;
      let tableHeight = pageHeight;
      tableHeight -= tableHeaderRect.height;
      tableHeight -= tableFooterRect.height;
      this.tableHeight = tableHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
.tableHeader,
.tableFooter {
  &.hide {
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}
</style>
