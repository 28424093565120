<template>
  <v-card class="tab-two-content">
    <form class="form-data">
      <div class="precaution">
        <div class="d-flex justify-space-between list-precautions">
          <div class="item">
            <label>注意事項</label>
            <div>
              <input
                @keyup="changeValueInput($event, 100)"
                @change="changeDataInput($event, typeInput.precaution)"
                type="text"
                name="precautions_add"
              />
            </div>
          </div>
          <div
            :disabled="disabledPlus.precaution"
            @click="addDataEmit(typeInput.precaution)"
            class="btn-over"
          >
            <button type="button" class="btn-plus">
              <v-icon> mdi-plus </v-icon>
            </button>
          </div>
        </div>
        <draggable
          class="scrollbar"
          v-model="listPrecaution"
          @change="$emit('editPrecaution', listPrecaution)"
        >
          <div v-for="(item, key) in listPrecaution" :key="key">
            <div class="item-checkbox">
              <input
                @keyup="changeValueInput($event, 100)"
                @change="changeValue($event, typeCheckBox.precaution)"
                v-bind:value="item.precautions_id"
                type="checkbox"
                class="checkbox-input"
              />
              <input
                v-model="item.precautions"
                class="txt"
                @change="$emit('editPrecaution', listPrecaution)"
              />
            </div>
            <div class="line" v-if="key < listPrecaution.length - 1"></div>
          </div>
        </draggable>
      </div>
      <div class="daily-item">
        <div class="list-daily">
          <div class="d-flex input-item">
            <div class="item w-50">
              <label>点検事項</label>
              <input
                @keyup="changeValueInput($event, 100)"
                @change="changeDataInput($event, typeInput.dailyItem)"
                type="text"
                name="inspection_item_add"
              />
            </div>
            <div class="item w-50 ml-17px">
              <label>主眼点</label>
              <input
                @keyup="changeValueInput($event, 100)"
                @change="changeDataInput($event, typeInput.dailyItem)"
                type="text"
                name="main_point_add"
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              class="btn-plus"
              :disabled="disabledPlus.dailyItem"
              @click="addDataEmit(typeInput.dailyItem)"
            >
              <v-icon> mdi-plus </v-icon>
            </button>
          </div>
        </div>
        <draggable
          class="scrollbar"
          v-model="listDailyItem"
          @change="$emit('editDailyItem', listDailyItem)"
        >
          <div v-for="(item, key) in listDailyItem" :key="key">
            <div class="d-flex justify-space-between">
              <div class="item-checkbox w-50">
                <input
                  @change="changeValue($event, typeCheckBox.dailyItem)"
                  v-bind:value="[item.inspection_item_id, item.main_point_id]"
                  type="checkbox"
                  class="checkbox-input"
                />
                <input
                  v-model="item.inspection_item"
                  class="txt"
                  @change="$emit('editDailyItem', listDailyItem)"
                />
              </div>
              <div class="item-checkbox w-50">
                <input
                  v-model="item.main_point"
                  class="txt"
                  @change="$emit('editDailyItem', listDailyItem)"
                />
              </div>
            </div>
            <div class="line" v-if="key < listDailyItem.length - 1"></div>
          </div>
        </draggable>
      </div>
    </form>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import Label from "../../../../components/forms/elements/Label";
export default {
  name: "TabTwoContent",
  components: { draggable, Label },
  data() {
    return {
      isDisabled: true,
      checkedPrecautionItem: [],
      checkedDailyItemItem: [],
      typeCheckBox: {
        precaution: "PRECAUTION",
        dailyItem: "DAILY_ITEM",
      },
      typeInput: {
        precaution: "PRECAUTION",
        dailyItem: "DAILY_ITEM",
      },
      disabledPlus: {
        precaution: true,
        dailyItem: true,
      },
    };
  },
  props: {
    listPrecaution: {
      type: Array,
      default() {
        return [];
      },
    },
    listDailyItem: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    enableInput() {
      this.isDisabled = false;
    },
    changeValue(event, type) {
      if (type === this.typeCheckBox.precaution) {
        if (event.target.checked) {
          this.checkedPrecautionItem.push(event.target.value);
        } else {
          this.checkedPrecautionItem = this.checkedPrecautionItem.filter(
            (x) => x !== event.target.value
          );
        }
      }
      if (type === this.typeCheckBox.dailyItem) {
        let value = event.target.value.split(",");
        if (event.target.checked) {
          this.checkedDailyItemItem.push({
            inspection_item_id: value[0],
            main_point_id: value[1],
          });
        } else {
          this.checkedDailyItemItem = this.checkedDailyItemItem.filter((x) => {
            return (
              x.inspection_item_id !== value[0] && x.main_point_id !== value[1]
            );
          });
        }
      }
      if (this.checkedDailyItemItem.length === 0) {
        this.$emit("changeValuePrecaution", this.checkedPrecautionItem);
      }
      if (this.checkedPrecautionItem.length === 0) {
        this.$emit("changeValueDailyItemItem", this.checkedDailyItemItem);
      }
      if (
        this.checkedDailyItemItem.length !== 0 &&
        this.checkedPrecautionItem.length !== 0
      ) {
        this.$emit("changeAllValue", {
          checked_precaution_item: this.checkedPrecautionItem,
          checked_daily_item_item: this.checkedDailyItemItem,
        });
      }
    },
    resetCheckBox() {
      this.checkedPrecautionItem = [];
      this.checkedDailyItemItem = [];
      document.querySelector(`input`).unchecked;
    },
    changeDataInput(event, type) {
      if (type === this.typeInput.precaution) {
        this.disabledPlus.precaution = this.checkBank(event.target.value);
      }
      if (type === this.typeInput.dailyItem) {
        this.disabledPlus.dailyItem =
          this.checkBank(
            document.querySelector(`input[name=main_point_add]`).value
          ) &&
          this.checkBank(
            document.querySelector(`input[name=inspection_item_add]`).value
          );
      }
    },
    checkBank(str) {
      if (str.trim().length === 0) {
        return true;
      } else {
        return false;
      }
    },
    resetDisabledPlus() {
      this.disabledPlus.precaution = true;
      this.disabledPlus.dailyItem = true;
    },
    addDataEmit(type) {
      if (type === this.typeInput.precaution) {
        this.$emit("addPrecaution");
      }
      if (type === this.typeInput.dailyItem) {
        this.$emit("addDailyItem");
      }
      this.resetDisabledPlus();
    },
    changeValueInput(event, max = 255) {
      if (event.target.value !== "") {
        let element = event.target;
        if (element.value.length > max) {
          element.value = element.value.slice(0, max);
        }
      }
      this.lastValue = event.target.value;
    },
  },
};
</script>

<style type="text/css" scoped>
.precaution .scrollbar {
  overflow-y: scroll;
  height: 120px;
  padding-right: 5px;
}

.daily-item .scrollbar {
  overflow-y: scroll;
  height: 160px;
  padding-right: 5px;
}

.line-item {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  background: #ccc;
}

.tab-two-content {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  box-shadow: none !important;
  padding: 20px 5px 20px 20px;
  width: 100%;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 10px;
}

.tab-two-content .item {
  position: relative;
  width: 100%;
}

.tab-two-content .item input {
  border: solid 2px #ccc !important;
  border-radius: 5px;
}

.tab-two-content .item label {
  position: absolute;
  top: -10px;
  font-size: 12px;
  font-weight: 400;
  left: 20px;
  background: #fff;
  z-index: 9999;
  padding-left: 10px;
  padding-right: 10px;
}

.tab-two-content .item input {
  padding: 15px;
  font-size: 16px;
  height: 45px;
  outline: none;
  width: 100%;
}

.tab-two-content .item-checkbox {
  margin-top: 10px;
  vertical-align: bottom;
  display: flex;
  margin-bottom: 10px;
}

.tab-two-content .item-checkbox .checkbox-input {
  width: 15px;
  height: 15px;
  border: 2px solid #bcbcbc;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 6px;
}

.tab-two-content .item-checkbox label {
  display: inline-block;
}

.line {
  border-bottom: solid 1px #ccc;
}

.w-50 {
  width: 50%;
  box-sizing: border-box;
}

.btn-plus {
  border-radius: 50%;
  min-height: 36px !important;
  width: 36px !important;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 0px !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  display: block !important;
}

.ml-17px {
  margin-left: 17px;
}

.justify-space-between {
  align-items: center !important;
}

.list-daily {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.list-daily .input-item {
  width: calc(100% - 38px) !important;
}

.list-precautions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.btn-over {
  cursor: pointer;
}

.precautions-text {
  width: calc(100% - 38px) !important;
}

.txt {
  width: calc(100% - 38px) !important;
  cursor: pointer;
  font-size: 14px;
}
</style>
