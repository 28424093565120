<template>
  <div class="labelWrapper" :style="style">
    <div class="label">
      {{ label }}
      <div v-if="required && editable" class="required">必須</div>
    </div>
    <div><slot></slot></div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto",
    },
  },
  computed: {
    style() {
      return `width:${this.width}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.labelWrapper {
  padding: 4px;
}
.label {
  font-size: 12px;
  padding: 4px;
  color: $color_primary;
}
.required {
  font-size: 8px;
  font-weight: bold;
  color: white;
  background-color: $color_require;
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
  transform: scale(0.8);
}
</style>
