<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader />
      </template>
    </DefaultLayout>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import CompanyHeader from "@/components/companyHeader/CompanyHeader.vue";

export default {
  data() {
    return {};
  },
  components: {
    DefaultLayout,
    CompanyHeader,
  },

  mounted() {},
  computed: {},
  methods: {},
};
</script>
