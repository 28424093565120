<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="body" v-for="item in items" :key="item.id">
      <div class="text-wrap">
        <div class="text">{{ item.machine_field_name }}</div>
      </div>
      <div class="text-wrap">
        <div class="text">{{ item.quantity }}台の新規登録が完了しました。</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed class="btn" color="close" @click="$emit('yes')">
        閉じる
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 10px;
  .v-card__title {
    padding: 20px;
  }
  .title {
    color: #00a688;
    font-weight: 400;
    font-size: 14px !important;
    margin-left: 6px;
  }
  .body {
    .text-wrap {
      padding: 0 0 26px 30px;
      position: relative;
      .text {
        text-align: left !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 80%;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 500;
      }
    }
  }
  .btn {
    margin-right: 26px;
    background: #ffffff;
    color: #00a688;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
}
</style>
